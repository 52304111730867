import React, { useContext, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import {
   Grid,
   Divider,
   Card,
   CardMedia,
   CardActionArea,
   CardContent,
   CircularProgress,
} from '@material-ui/core';

import {AppContext} from '../../contexts/AppContext';
import CategoryGrid from "../categories/CategoryGrid";
import CategoryDAO from "../../model/dao/CategoryDAO";
import {
   DBCategory,
} from '../../model/interface/DBModels';

type Trade = {
   id: number,
   name: string,
}

let trades: Trade[] = [
   {
      id: 1,
      name: "Administrative Services"
   },
   {
      id: 2,
      name: "Automotive Mechanic"
   },
   {
      id: 3,
      name: "Carpentry"
   },
   {
      id: 4,
      name: "Cleaning Services"
   },
   {
      id: 5,
      name: "Electricians"
   },
   {
      id: 6,
      name: "Facility and Plant Management"
   },
   {
      id: 7,
      name: "General Contractors"
   },
   {
      id: 8,
      name: "Landscaping and Garden"
   },
   {
      id: 9,
      name: "Engineering"
   },
   {
      id: 10,
      name: "Movers Packers Logistics"
   },
   {
      id: 11,
      name: "Plumbers"
   },
   {
      id: 12,
      name: "Restaurant and Food Service"
   },
   {
      id: 13,
      name: "Security Services"
   },
   {
      id: 14,
      name: "Welder"
   },
   {
      id: 15,
      name: "Pipefitter"
   },
   {
      id: 16,
      name: "Lab Technician"
   },
   {
      id: 17,
      name: "Machinist"
   },
   {
      id: 18,
      name: "Farmer"
   },
   {
      id: 19,
      name: "Painter"
   },
]

export default function TradesPage() {

   const context = useContext(AppContext);
   const [categories, setCategories] = useState<DBCategory[]>([]);
   const [isLoading, setIsLoading] = useState(false);
   const [title, setTitle] = useState("All Trades");

   const handleTradeClick = (trade: Trade) => {

      return () => {
         CategoryDAO.getTopLevelTrades(context.token, trade.id)
         .then((categories) => {
            setCategories(categories.categories);
            setIsLoading(false);
            setTitle(trade.name)
         }).catch(() => {
            setIsLoading(false);
         });
      }
   }

   return (
      <div className="categories-page page-offset">
         <Grid container>
            <Grid item xs={12}>
               <Typography variant="h5" noWrap>
                  {title}
               </Typography>
               <Divider/>
            </Grid>
            <Grid item xs={12}>
               { !categories.length ?
                  <div>
                     <Grid
                        container
                        item
                        xs={12}
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={5}
                     >
                        {trades.map(
                           (trade, i) => (
                              <Grid item key={i} xl={2} md={3} sm={6} xs={12}>
                                 <div onClick={handleTradeClick(trade)}>
                                    <Card variant="outlined"
                                       className="grid-item-display"
                                       key={trade.id}
                                    >
                                       {/* <CardMedia
                                          className="grid-item-display-image"
                                          image={category.image}
                                          title={category.name}/> */}
                                       <CardActionArea>
                                       <CardContent>
                                          <div className="default-text">
                                             {trade.name}
                                          </div>
                                       </CardContent>
                                       </CardActionArea>
                                    </Card>
                                 </div>
                              </Grid>
                           )
                        )}
                     </Grid>
                  </div>
               :
                  <div></div>
               }
               { isLoading ? 
                  <CircularProgress size={"4em"}/>
               :
                  <CategoryGrid {...{categoryList: categories, fromTop: true}}/>
               }
            </Grid>
         </Grid>
      </div>
   );
}