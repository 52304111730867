import React, { useContext } from 'react';
import {
  useHistory,
} from 'react-router-dom';


import {
  Grid, Paper, List, ListItem, ListItemText
} from '@material-ui/core';
import {AppContext} from '../../contexts/AppContext';

type Props = {
  selected: number,
};

const AccountSidebar = function(props: Props) {
  const context = useContext(AppContext);
  let history = useHistory();
  return (
    <Grid item xs={12} md={2}>
      <Paper>
        <List component="nav">
          <ListItem button onClick={() => {history.push("/orders")}} selected={props.selected === 0}>
            <ListItemText primary="Order History" />
          </ListItem>
          <ListItem button onClick={() => {history.push("/settings")}} selected={props.selected === 1}>
            <ListItemText primary="Account Settings" />
          </ListItem>
          <ListItem button onClick={() => {history.push("/addresses")}} selected={props.selected === 2}>
            <ListItemText primary="Addresses" />
          </ListItem>
          <ListItem button onClick={() => {history.push("/payments")}} selected={props.selected === 3}>
            <ListItemText primary="Payments" />
          </ListItem>
          <ListItem button onClick={() => {context.signOut(); history.push("/")}} selected={props.selected === 4}>
            <ListItemText primary="Sign Out" />
          </ListItem>
        </List>
      </Paper>
    </Grid>
   );
}

export default AccountSidebar;
