import React, { useState, useContext } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  Paper,
  TextField,
  FormHelperText,
  Typography,
  Box
} from '@material-ui/core';

import AccountPageWrapper from '../../components/account/AccountPageWrapper';
import { CLIENT_RENEG_LIMIT } from 'tls';
import Endpoint from '../../model/request/Endpoint';
import { AppContext } from '../../contexts/AppContext';
import { Credentials } from '../../model/interface/DBModels';

export default function AccountSettingsPage() {

  return (
    <AccountPageWrapper
      title={"Account Settings"}
      selected={1}
      inside={<InsidePage />} />
  );
}

const EMAIL_REGEX = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

function InsidePage() {
  // const [resetData , setResetData] = useState<any>({
  //   passwordError: false,
  //   password:''
  // })  
  const [resetData, setResetData] = useState<any>('')
  const [accountDetail, setAccountDetail] = useState<any>({ email: '', password: '' })
  const [accountDetailError, setAccountDetailError] = useState<any>({ email: '', password: '' })

  const account = Endpoint.for<{}, {}>('account')
  const auth = useContext(AppContext);

  // const handleChange = (e: any) => {
  //   setAccountDetail({ ...accountDetail, [e.target.name]: e.target.value })
  // }
  const handleChangeError = (e: any) => {
    if (e.target.name === "email") {
      if (!e.target.value) {
        setAccountDetailError({ ...accountDetailError, [e.target.name]: '' })
      }
      else {
        if (EMAIL_REGEX.test(e.target.value)) {
          setAccountDetailError({ ...accountDetailError, [e.target.name]: '' })
        }
        else {
          setAccountDetailError({ ...accountDetailError, [e.target.name]: 'Please enter correct email' })
        }
      }
    }
    else if (e.target.name === "password") {
      if (!e.target.value) {
        setAccountDetailError({ ...accountDetailError, [e.target.name]: '' })
      }
      else {
        if (PASSWORD_REGEX.test(e.target.value)) {
          setAccountDetailError({ ...accountDetailError, [e.target.name]: '' })
        }
        else {
          setAccountDetailError({ ...accountDetailError, [e.target.name]: 'Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character' })
        }
      }
    }
  }

  const handleChange = (e: any) => {
    setAccountDetail({ ...accountDetail, [e.target.name]: e.target.value })
    handleChangeError(e)
  }
  console.log("!!accountDetail.password && !!!accountDetail.email", !!accountDetail.password && !!!accountDetail.email)
  const submitData = async () => {
    if (!!accountDetail.password || !!accountDetail.email) {
      if (!!accountDetail.password && !!accountDetail.email) {
        if (!!!accountDetailError.email && !!!accountDetailError.password) {
          const data = {
            email: accountDetail.email,
            newPassword: accountDetail.password
          }
          const response = await account.put(data, { token: auth.token })
          setAccountDetail({ email: '', password: '' })
        }
      }
      else if (!!accountDetail.password && !!!accountDetail.email) {
        if (!!!accountDetailError.password) {
          const data = {
            newPassword: accountDetail.password
          }
          const response = await account.put(data, { token: auth.token })
          setAccountDetail({ email: '', password: '' })
        }

      }
      else if (!!!accountDetail.password && !!accountDetail.email) {
        if (!!!accountDetailError.email) {
          const data = {
            email: accountDetail.email
          }
          const response = await account.put(data, { token: auth.token })
          setAccountDetail({ email: '', password: '' })
        }
      }
    }

    // }
  };


  return (
    <>
      <Grid item xs> {/* main page */}
        <Paper elevation={3} className="order-history-paper">
          <div className="loading-screen">
            <Typography variant="subtitle1" align="center">
              {/* under construction! come back later :) */}
              <div className="ln-form">
                <form className="ln-textBoxes" noValidate autoComplete="off">
                  <div className="ln-textBox" style={{ display: "flex", justifyContent: "center", marginBottom: '20px ', alignItems: "center" }}>
                    {/* <Typography style={{ minWidth: "120px", textAlign: "left" }}>Email : </Typography> */}
                    <Box sx={{ width: "100%" }}>
                      <TextField error={!!accountDetailError.email} fullWidth label="Email" placeholder="Email" InputLabelProps={{ shrink: true }} className="ln-passwordln" name="email" variant="outlined" aria-label="New-Password" inputProps={{ "aria-label": "New-Password", style: { padding: "10.5px 14px" } }} onChange={(e: any) => handleChange(e)} />
                      {accountDetailError.email && <FormHelperText error>{accountDetailError.email}</FormHelperText>}
                    </Box>
                  </div>
                  <div className="ln-textBox" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {/* {resetData.emailError ?
                                 <TextField error className="ln-emailField" name="email" variant="outlined" label="Email" aria-label="Email Address" inputProps={{"aria-label": "Email Address"}} helperText="Please enter a valid email address." onChange={handleEmail} />:
                                 <TextField className="ln-emailField" name="email" variant="outlined" label="Email" aria-label="Email Address" inputProps={{"aria-label": "Email Address"}} onChange={handleEmail} />} */}
                    {/* <Typography style={{ minWidth: "120px", textAlign: "left" }}>New Password : </Typography> */}
                    <Box sx={{ width: "100%" }}>
                      <TextField error={!!accountDetailError.password} fullWidth label="New Password" placeholder="New Password" InputLabelProps={{ shrink: true }} className="ln-passwordln" name="password" variant="outlined" aria-label="New-Password" inputProps={{ "aria-label": "New-Password", style: { padding: "10.5px 14px" } }} onChange={(e: any) => handleChange(e)} />
                      {accountDetailError.password && <FormHelperText error>{accountDetailError.password}</FormHelperText>}
                    </Box>
                  </div>
                  {/* <div className="ln-textBox">
                                 <PasswordField passwordError={this.state.passwordError} handlePassChange={this.handlePass} />
                              </div> */}
                  <div className="ln-buttons" style={{ marginTop: "10px", textAlign: 'right' }}>
                    {/* <Button className="ln-button ln-left-btn" onClick={this.handleSignUp} variant="outlined" color="primary" disableElevation>
                                       Sign Up
                                 </Button> */}
                    <Button
                      className="ln-button ln-right-btn"
                      variant="contained"
                      // onClick={this.handleCred}
                      color="primary"
                      // disabled={!this.state.allowed}
                      onClick={submitData}
                    >
                      Submit
                      {/* {accountDetail.email && accountDetail.password ? "Submit" : (accountDetail.email && !accountDetail.password ? "Email" : (!accountDetail.email && accountDetail.password &&  "Password"))} */}
                      {/* {this.state.pending && <CircularProgress className="u-marginLeft-1" color="secondary" size={20}/>} */}
                    </Button>
                    {/* {console.log("resetData===>",resetData)} */}
                  </div>
                </form>
              </div>
            </Typography>
          </div>
        </Paper>
      </Grid>
      {/* <Grid item xs={12} className="message-banner">
    <Alert severity="success" variant="filled">
      <AlertTitle>Password Changed Successful !</AlertTitle>      
    </Alert>
  </Grid> */}
    </>
  );
}

