import React, {Component} from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Alert, AlertTitle } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';

import logo from "../assets/AS-title-transparent-cropped.png";

import {AppContext} from '../contexts/AppContext';
import UserDAO from '../model/dao/UserDAO';
import CartDAO from "../model/dao/CartDAO"
import { Credentials } from '../model/interface/DBModels';
import PasswordField from './PasswordField';

type CustomProps = WithSnackbarProps & {
   showSignUp: () => void,
   altCb?: (creds: Credentials) => void,
   closeCb: () => void,
};

type State = {
   emailError: boolean,
   passwordError: boolean,
   email: string,
   password: string,
   showAlert: boolean,
   currentId: string,
   pending: boolean,
   allowed: boolean,
};


class LoginHelper extends Component<CustomProps, State> {
   state: State = {
      email: "",
      emailError: false,
      passwordError: false,
      password: "",
      showAlert: false,
      currentId: "",
      pending: false,
      allowed: false,
   };
   static contextType = AppContext;

   render() {
      return (
               <div>
                  <div className="ln-main">
                     <div className="ln-logo">
                        <img width="278" height="28" alt="AS logo" src={String(logo)} />
                     </div>
                     <div className="ln-form">
                        <form className="ln-textBoxes" noValidate autoComplete="off">
                              <div className="ln-textBox">
                                 {this.state.emailError ?
                                 <TextField error className="ln-emailField" variant="outlined" label="Email" aria-label="Email Address" inputProps={{"aria-label": "Email Address"}} helperText="Please enter a valid email address." onChange={this.handleEmail} />:
                                 <TextField className="ln-emailField" variant="outlined" label="Email" aria-label="Email Address" inputProps={{"aria-label": "Email Address"}} onChange={this.handleEmail} />}
                              </div>
                              <div className="ln-textBox">
                                 <PasswordField passwordError={this.state.passwordError} handlePassChange={this.handlePass} />
                              </div>
                              <div className="ln-buttons">
                                 <Button className="ln-button ln-left-btn" onClick={this.handleSignUp} variant="outlined" color="primary" disableElevation>
                                       Sign Up
                                 </Button>
                                 <Button
                                    className="ln-button ln-right-btn"
                                    variant="contained"
                                    onClick={this.handleCred}
                                    color="primary"
                                    disabled={!this.state.allowed}
                                 >
                                    Login
                                    {this.state.pending && <CircularProgress className="u-marginLeft-1" color="secondary" size={20}/>}
                                 </Button>
                              </div>
                        </form>
                     </div>
                  </div>
                  <div className="ln-alert">
                     <Alert severity="error" style={{display: this.state.showAlert ? 'flex' : 'none' }} >
                           <AlertTitle>Error</AlertTitle>
                           Invalid email or password.
                     </Alert>
                  </div>
               </div>
         );
   }

   handleSignUp = async () => {
      this.props.showSignUp()
   }

   handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value && (event.target.value.indexOf("@") >= 0)) {
         this.setState({email: event.target.value, emailError: false});
         if (this.state.password) {
            this.setState({allowed: true});
         }
      } else {
         this.setState({email: event.target.value, emailError: true});
         this.setState({allowed: false});
      }
   };

   handlePass = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({password: event.target.value});
      if (event.target.value && this.state.email && !this.state.emailError) {
         this.setState({allowed: true});
      } else {
         this.setState({allowed: false});
      }
   };


   handleCred = async () => {
      let guestId = this.context.account.id;
      let lastLoginWasGuest = this.context.account.guest;
      
      try {
         this.setState({
            emailError: false,
            passwordError: false,
            showAlert: false,
            pending: true,
         });

         const response = await UserDAO.login(
               this.state.email,
               this.state.password
         );
         if (!this.props.altCb) {
            console.log("no altCb");
            this.context.setCredentials(response);
         } else {
            console.log("using altCb");
            this.props.altCb(response);
         }

         // persist token
         if (response.token)
            localStorage.setItem("AS token", response.token);

         // copy cart
         if(guestId !== "" && lastLoginWasGuest) {
            CartDAO.copyItems(response.token, {guestId: guestId});
         }

         //display snackbar
         this.props.enqueueSnackbar("Login successful.", {variant: "success"});

         this.props.closeCb();
      } catch (e) {
         this.setState({
            emailError: !this.state.email,
            passwordError: !this.state.email,
            showAlert: true,
         });
      } finally {
         this.setState({pending: false});
      }
   };

}

export default withSnackbar(LoginHelper);