import React, {useState, useContext} from "react";
import {
   RouteProps, useHistory,
 } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Alert, AlertTitle } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';

import SecureDAO from '../../model/dao/SecureDAO';

import logo from "../../assets/AS-title-transparent-cropped.png";

import {AppContext} from "../../contexts/AppContext";

type Event = {
   target: {
      value: string
   }
};

type CustomState = {
   referrer: {
      pathname: string
   }
}

export const LockScreen: React.FunctionComponent<RouteProps> = (props:RouteProps) => {
   const [codeField, setCodeField] = useState("");
   const [allowed, setAllowed] = useState(false);
   const [pending, setPending] = useState(false);
   const [showAlert, setShowAlert] = useState(false);

   const context = useContext(AppContext);
   let history = useHistory();

   const handleChange = (event: Event) => {
      const code = event.target.value;
      setCodeField(code);
      if (code) {
         setAllowed(true);
      } else {
         setAllowed(false);
      }
   };

   const handleSubmitCode = async () => {
      setPending(true);

      await SecureDAO.secure(codeField).then(
         (res) => {
            if (res) {
               setPending(false);
               context.setSecure(res);
            } else {
               throw new Error("Incorrect Code");
            }
         }
      ).then(() => {
         if (props.location && props.location.state) {
            history.push((props.location.state as CustomState).referrer.pathname);
         } else {
            history.push("/");
         }
      }).catch(() => {
         setShowAlert(true);
         setPending(false);
      })
   }

   const checkForSubmit = (event: React.KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.key === "Enter" && allowed) {
         handleSubmitCode();
      }
   };

   return (
      <div className="u-marginTop-8">
         <div className="ln-main">
            <div className="ln-logo">
               <img width="278" height="28" alt="AS logo" src={String(logo)} />
               <div className="u-marginVertical-1">
                  Thanks for visiting. Our site is currently under construction.
               </div>
               <div className="u-marginBottom-1">
                  Only authorized users with an access code may enter the site at this time.
               </div>
               <div>
                  Check back soon!
               </div>
            </div>
            <div className="ln-form">
               <div className="lockscreen-textBoxes">
                     <div className="ln-textBox">
                        <TextField 
                           className="ln-emailField"
                           variant="outlined"
                           label="Code"
                           onChange={handleChange}
                           onKeyUp={checkForSubmit}
                        />
                     </div>
                     <div className="ln-buttons">
                        <Button
                           className="ln-button"
                           variant="contained"
                           onClick={handleSubmitCode}
                           color="primary"
                           disabled={!allowed}
                           >
                           Enter Site
                           {pending && <CircularProgress className="u-marginLeft-1" color="secondary" size={20}/>}
                        </Button>
                     </div>
               </div>
            </div>
         </div>
         <div className="ln-alert">
            <Alert severity="error" style={{display: showAlert ? 'flex' : 'none' }} >
                  <AlertTitle>Error</AlertTitle>
                  Invalid code.
            </Alert>
         </div>
      </div>
   );
}
