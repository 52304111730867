import ResultDAO from "../../model/dao/ResultDAO";
import { DBAbstractItem, DBFilter, DBSearchResults } from "../../model/interface/DBModels";

type QueryParams = { [param: string]: string[] };
type Props = {
   token?: string,
   searchText?: string,
   queryParams?: QueryParams
};

class SearchResults {
   searchText: string;
   queryParams: QueryParams;
   token: string;
   results?: DBAbstractItem[];
   filters?: DBFilter[];

   constructor(props: Props = {}) {
      const {
         token = '',
         searchText = '',
         queryParams = {
               perPage: ['20']
         }
      } = props;

      this.token = token;
      this.searchText = searchText;
      this.queryParams = queryParams;
      this.results = undefined;
   }

   refresh() {
      this.results = undefined;
   }

   async getResults(): Promise<DBSearchResults<DBAbstractItem>> {
      // Add logic to not call extra times
      // if (this.results) {
      //    return results;
      // }

      const response = await ResultDAO.searchItems(this.queryParams, this.token);
      this.results = response.results;
      this.filters = response.filters;

      return response;
   }
}

export default SearchResults;