import React, { useState, useContext } from 'react';

import {
  NavLink as RouterLink,
} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Paper, Button, CircularProgress
} from '@material-ui/core';
import ResultDAO from "../../../model/dao/ResultDAO";
import {AppContext} from '../../../contexts/AppContext';
import CheckoutStepper from "../CheckoutStepper";

export default function ShippingForm() {
   const context = useContext(AppContext);

   const [loading, setLoading] = useState(true);
   const [errorMessage, setError] = useState(false);

   const [cartTotal, setCartTotal] = useState(0);

   React.useEffect(() => {
      let isActive = true;

      setLoading(true);
      setError(false);

      ResultDAO.getCart({}, context.token)
        .then((cartItems) => {
            if (isActive) {
               setCartTotal(Number(cartItems.totalPrice));
               setLoading(false);
               setError(false);
            }
        }).catch(() => {
            if (isActive) {
               setError(true);
               setLoading(false);
            }
        });

      return () => {
        isActive = false;
      };
    }, [context.token]);

   return (
     <div> {/* outer wrapper */}
        <Grid item xs={12}> {/* title */}
          <CheckoutStepper step={2}/>
        </Grid>
        <Paper elevation={3}> {/* info */}
          {
           loading
           ?
           <div className="loading-screen">
             <Typography variant="subtitle1" noWrap align="center">
               <CircularProgress size={'1em'}/>
               <br/>
               Loading shipping rates...
             </Typography>
           </div>
           :
            <Grid item xs={12} className="checkout-form-inner">
              <Typography variant="h6" noWrap gutterBottom={true}>
                 Shipping
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                     Shipping costs for this order are estimated to be <b>${(cartTotal * 0.10).toFixed(2)}</b>.
                  </Typography>
                  <br />
                  <Typography variant="body1">
                     Disclaimer: Shipping costs are <b>not final</b> until you complete your order. <br/>
                     The number provided here is an estimate, and is estimated at 10% of your total cost.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          }
        </Paper>
        <Grid item container xs={12} justify="space-between">
          <Button
           variant="contained" color="primary" component={RouterLink} to={`/checkout/payment`}
           disabled={loading} className="checkout-button" disableElevation
          >
            Back
          </Button>
          <Button
           variant="contained" color="primary" component={RouterLink} to={`/checkout/review`}
           disabled={loading} className="checkout-button" disableElevation
          >
            Next
          </Button>
        </Grid>
     </div>
   );
}
