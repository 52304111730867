import React, {useContext, useState} from "react";

import Button, {ButtonProps} from "@material-ui/core/Button";
import {
   DialogContent, DialogActions, Dialog,
   Typography, DialogTitle, CircularProgress,
} from "@material-ui/core";

import {AppContext} from "../../../contexts/AppContext";
import AddressDAO from "../../../model/dao/AddressDAO";

type Props = ButtonProps & {
  addressId: string | undefined,
  reloadFunction: any,
}

export default function AddressDeletionButton(props:Props) {
   const context = useContext(AppContext);

   const [open, setOpen] = React.useState(false);
   const [loading, setLoading] = useState<boolean>(false);

  // ------------- show/hide dialogue ------------- //

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // ------------- cancel order ------------- //
  const deleteAddress = async () => {
    if(props.addressId !== undefined) {
      setLoading(true);
      await AddressDAO.deleteAddress(context.token, props.addressId);
      setLoading(false);
      handleClose();
      props.reloadFunction();
    }
  }

   return (
      <div>
         <Button variant="outlined" onClick={handleClickOpen}>
            delete address
          </Button>

         <Dialog open={open} maxWidth="lg" disableBackdropClick disableEscapeKeyDown keepMounted>
            <DialogTitle>
               <Typography variant="h5" color="primary">
                  Delete Address
               </Typography>
            </DialogTitle>
            {
              (loading)
              ?
                <DialogContent dividers>
                  <Typography variant="body1" noWrap align="center">
                    <CircularProgress size={'1em'}/>
                  </Typography>
                </DialogContent>
              :
                <DialogContent dividers>
                  <Typography variant="body1">
                      Are you sure you would like to delete this address?
                  </Typography>
                </DialogContent>
            }
            <DialogActions>
              <Button variant="outlined" onClick={handleClose} disabled={loading}>
                  keep address
              </Button>
              <Button variant="contained" color="primary" onClick={deleteAddress} disabled={loading}>
                  delete address
              </Button>
            </DialogActions>
         </Dialog>
      </div>
   );
}
