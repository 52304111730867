import React, { useState, useContext } from 'react';

import {
  useHistory,
  NavLink as RouterLink,
} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Paper, Button, CircularProgress, Divider
} from '@material-ui/core';
import { DBAddress, DBCard, DBCartItem } from '../../../model/interface/DBModels';
import ResultDAO from "../../../model/dao/ResultDAO";
import OrderDAO from "../../../model/dao/OrderDAO";
import StripeDAO from "../../../model/dao/StripeDAO";
import {AppContext} from '../../../contexts/AppContext';
import CheckoutStepper from "../CheckoutStepper";

import {codeToLabel} from '../../_helpers/CountryType';

import DisplayPayment from '../../orders/DisplayPayment';
import DisplayAddress from '../../orders/DisplayAddress';
import DisplayItems from '../../orders/DisplayItems';

type Props = {
  isGuestAccount: boolean,
  accountEmail: string,
  orderShippingAddress: DBAddress,
  orderPaymentMethod: string,
  setOrderId: any,
  setOrderConfirmed: any
}

export default function ReviewForm(props: Props) {
   const context = useContext(AppContext);
   let history = useHistory();

   const [loading, setLoading] = useState(true);
   const [errorMessage, setError] = useState(false);

   const [cartItems, setCartItems] = useState<DBCartItem[]>([]);

   const [paymentMethodDetails, setPaymentMethodDetails] = useState<any>();

   // -------------- get cart total + items -------------- //

   const loadingChecker = (cLoad: boolean, pLoad: boolean) => {
     if(cLoad === false && pLoad === false) {
       setLoading(false);
     }
   }

   React.useEffect(() => {
    let isActive = true;
    let cartLoading = true;
    let paymentLoading = true;

    setLoading(true);
    setError(false);

    ResultDAO.getCart({}, context.token)
      .then((cartItems) => {
          if (isActive) {
            setCartItems(cartItems.results)
            // setLoading(false)
            cartLoading = false;
            loadingChecker(cartLoading, paymentLoading);
          }
      }).catch(() => {
          if (isActive) {
            setError(true)
            // setLoading(false)
            cartLoading = false;
            loadingChecker(cartLoading, paymentLoading);
          }
      });

    setLoading(true);

    StripeDAO.getAllCards(context.token)
    .then((paymentList) => {
        if (paymentList) {
          let cards = (paymentList as any).cards;
          let foundCard = cards.find((c: DBCard) => c.id === props.orderPaymentMethod)
          if(foundCard === undefined || foundCard === null) {
            // setLoading(false);
            paymentLoading = false;
            loadingChecker(cartLoading, paymentLoading);
          }
          else {
            setPaymentMethodDetails(foundCard);
            // setLoading(false);
            paymentLoading = false;
            loadingChecker(cartLoading, paymentLoading);
          }
        }
    }).catch(() => {
        if (isActive) {
          setError(true);
          // setLoading(false);
          paymentLoading = false;
          loadingChecker(cartLoading, paymentLoading);
        }
    });

      return () => {
        isActive = false;
      };
    }, [context.token, props.orderPaymentMethod]);

    // -------------- submit order -------------- //

    const submitOrder = async () =>
    {
      let countryLabel = codeToLabel(props.orderShippingAddress.country);
      console.log(countryLabel);
      let orderToSubmit = {
        "paymentMethod": props.orderPaymentMethod,
        "shippingCity": props.orderShippingAddress.city,
        "shippingCountry": countryLabel,
        "shippingLine1": props.orderShippingAddress.line1,
        "shippingLine2": props.orderShippingAddress.line2,
        "shippingPostalCode": props.orderShippingAddress.postalCode,
        "shippingState": props.orderShippingAddress.state,
        "shippingName": props.orderShippingAddress.name,
        "shippingPhone": props.orderShippingAddress.phone,
        "shippingEmail": props.accountEmail,
        "status": "Pending"
      }
      let order = await OrderDAO.createOrder(orderToSubmit, context.token);
      props.setOrderId((order as any).id);
      props.setOrderConfirmed(true);
      history.push("/checkout/complete");
    }

    // -------------- the actual page -------------- //

   return (
     <div> {/* outer wrapper */}
        <Grid item xs={12}> {/* title */}
          <CheckoutStepper step={3}/>
        </Grid>
        <Paper elevation={3}> {/* info */}
          {
           loading
           ?
           <div className="loading-screen">
             <Typography variant="subtitle1" noWrap align="center">
               <CircularProgress size={'1em'}/>
               <br/>
               Loading review...
             </Typography>
           </div>
           :
            <Grid item xs={12} className="checkout-form-inner">
              <Grid container justify="flex-start">
                <DisplayAddress
                  title="Shipping Address"
                  shippingCity={props.orderShippingAddress.city}
                  shippingCountry={props.orderShippingAddress.country}
                  shippingLine1={props.orderShippingAddress.line1}
                  shippingLine2={props.orderShippingAddress.line2}
                  shippingPostalCode={props.orderShippingAddress.postalCode}
                  shippingState={props.orderShippingAddress.state}
                  shippingName={props.orderShippingAddress.name}
                  shippingPhone={props.orderShippingAddress.phone}
                  editable={true}/>
              </Grid>
              <Grid item xs={12} className="padded-divider">
                <Divider />
              </Grid>
              {
                (paymentMethodDetails !== undefined)
                ?
                <Grid container justify="flex-start">
                  <DisplayPayment
                    last4Digits={paymentMethodDetails.last4Digits}
                    cardBrand={paymentMethodDetails.brand}
                    cardName={paymentMethodDetails.name}
                    editable={true}/>
                  <DisplayAddress
                    title="Billing Address"
                    shippingCity={paymentMethodDetails.billingAddress.city}
                    shippingCountry={paymentMethodDetails.billingAddress.country}
                    shippingLine1={paymentMethodDetails.billingAddress.line1}
                    shippingLine2={paymentMethodDetails.billingAddress.line2}
                    shippingPostalCode={paymentMethodDetails.billingAddress.postalCode}
                    shippingState={paymentMethodDetails.billingAddress.state}
                    shippingName={paymentMethodDetails.name}
                    shippingPhone={""}
                    editable={true}/>
                </Grid>
                :
                <Grid container/>
              }

              <Grid item xs={12} className="padded-divider">
                <Divider />
              </Grid>
              <DisplayItems 
                items={cartItems}
                status="Pending"/>
            </Grid>
          }
        </Paper>
        <Grid item container xs={12} justify="space-between">
          <Button
           variant="contained" color="primary" component={RouterLink} to={`/checkout/shipping`}
           disabled={loading} className="checkout-button" disableElevation
          >
            Back
          </Button>
          <Button
           variant="contained" color="primary" onClick={() => submitOrder()}
           disabled={loading} className="checkout-button" disableElevation
          >
            Place Your Order
          </Button>
        </Grid>
     </div>
   );
}
