import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
   createMuiTheme,
   ThemeProvider,
} from '@material-ui/core/styles';

import logo from '../assets/AS-title-transparent-cropped.png';

//old colors: primary: '#2F5482' secondary: '#AD7B3E'

const customTheme = createMuiTheme({
   palette: {
      primary: {
         main: '#FFF'
      },
      secondary: {
         main: '#AD7B3E',
      }
   },
});


export default function CheckoutNavBar() {
   return (
      <ThemeProvider theme={customTheme}>
         <AppBar position="static">
            <Toolbar>
                <div className="main-image">
                   <img width="300" height="31" alt="AS logo" src={String(logo)} />
                </div>
            </Toolbar>
         </AppBar>
      </ThemeProvider>
   );
}
