import React, {MouseEvent, useState, useContext, ChangeEvent} from "react";
import { useSnackbar } from 'notistack';

import AssemblyDAO from "../../model/dao/AssemblyDAO";
import Button, {ButtonProps} from "@material-ui/core/Button";
import {AppContext} from "../../contexts/AppContext";
import {
   DialogContent, DialogActions, Dialog,
   Typography, TextField,
} from "@material-ui/core";
import { Credentials } from "../../model/interface/DBModels";
import AccountPromptButton from "../search/components/AccountPromptButton";

type Props = ButtonProps & {
   newProjectAdded: () => void,
};

export default function CreateProjectDialogButton(props:Props) {
   const {newProjectAdded: newProjectAdded, ...rest} = props;
   
   const context = useContext(AppContext);
   const {enqueueSnackbar} = useSnackbar();
   
   const [tempCreds, setTempCreds] = useState<Credentials>({token: "", account: {id:"",email:"",firstName:"",lastName:"",guest:false}});
   const [open, setOpen] = useState(false);
   const [name, setName] = useState('');
   const [canCreate, setCanCreate] = useState(false);

   const handleCreateClick = (event: MouseEvent) => {
      event.preventDefault();

      AssemblyDAO.createAssembly({name}, tempCreds.token || context.token)
         .then(() => {
            newProjectAdded();
            enqueueSnackbar("Project '" + name + "' created.", {variant: "success"});
            setName('');
         })
         .catch(() => {
            enqueueSnackbar("Unable to create project.", {variant: "error"});
         });
      handleClose();
   };

   const altCb = (creds:Credentials) => {
      setTempCreds(creds);
      setOpen(true);
   }

   const handleClickOpen = () => {
      setOpen(true);
   };

   const handleClose = () => {
      setOpen(false);
      if (tempCreds.token) {
         context.setCredentials(tempCreds);
      }
   };

   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
      if (event.target.value === "") {
         setCanCreate(false);
      } else {
         setCanCreate(true);
      }
   }

   return (
      <div>
         <AccountPromptButton
            buttonLabel={"Create Project"}
            actionHandler={handleClickOpen}
            altCb={altCb}
            fullWidth
            variant="contained"
            color="primary"
            disableGuest={true}
            {...rest}
         />

         <Dialog
            open={open}
            maxWidth="lg"
            onBackdropClick={handleClose}
            onEscapeKeyDown={handleClose}
            keepMounted
         >
            <DialogContent dividers>
               <div className="u-paddingBottom-2" >
                  <Typography variant="h4" color="primary">
                     New Project
                  </Typography>
               </div>

               <TextField id="project-name" value={name} onChange={handleChange} label="Project Name" variant="outlined"/>
            </DialogContent>

            <DialogActions>
               <Button
                  variant="outlined"
                  onClick={handleClose}
               >
                  Cancel
               </Button>

               <Button 
                  variant="contained"
                  color="primary"
                  disabled={!canCreate}
                  onClick={handleCreateClick}
               >
                  Create
               </Button>
            </DialogActions>
         </Dialog>
      </div>
   );
}