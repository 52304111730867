import {Account} from "./DBModels"
import { QueryParamsModel } from "./Models";

/**
 * Creates an empty account object
 */
export function buildEmptyAccount(): Account {
   return {
      id: "",
      email: "",
      firstName: "",
      lastName: "",
      guest: true,
   };
}

/**
 * Creates an empty query params object
 */
 export function buildDefaultQueryParams(): QueryParamsModel {
   return {
      searchText: "",
      page: 1,
      perPage: 20,
      sort: "",
      filters: {},
   };
}

