import DAO from "./DAO";
import Endpoint from "../request/Endpoint";
import {
   DBAddress,
} from "../interface/DBModels";

class AddressDAO extends DAO {
   private readonly getAddressesEndpoint: Endpoint<{}, DBAddress[]>;
   private readonly addAddressEndpoint: Endpoint<DBAddress, {}>;
   private readonly updateAddressEndpoint: Endpoint<{}, {}>;
   private readonly deleteAddressEndpoint: Endpoint<{}, {}>;

   constructor() {
      super();

      this.getAddressesEndpoint = Endpoint.for<{}, DBAddress[]>('address');
      this.addAddressEndpoint = Endpoint.for<DBAddress, {}>('address');
      this.updateAddressEndpoint = Endpoint.for<DBAddress, {}>('addressById');
      this.deleteAddressEndpoint = Endpoint.for<{}, {}>('addressById');
   }

   async getAddresses(token: string = ""): Promise<DBAddress[]> {
      console.log("getting addresses");

      const response = await this.getAddressesEndpoint.get(
         {token},
      );

      this.validateResponse(response);

      return response;
   }

   async addAddress(data: DBAddress, token: string = "") {
      console.log("adding item to assembly");

      const urlParams = {
         token,
      }

      const response = await this.addAddressEndpoint.post(
         data,
         urlParams,
      );

      this.validateResponse(response);
   }

   async updateAddress(data: DBAddress, token: string = "", addressId: string) {

      const urlParams = {
         token,
         addressId,
      }

      const response = await this.updateAddressEndpoint.put(
         data,
         urlParams,
      );

      this.validateResponse(response);

      return response;
   }

   async deleteAddress(token: string = "", addressId: string) {
      const urlParams = {
         token,
         addressId,
      }

      const response = await this.deleteAddressEndpoint.delete(urlParams);

      if (response) {
         this.validateResponse(response);
      }
   }
}

const instance = new AddressDAO();

Object.freeze(instance);

export default instance;