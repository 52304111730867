import React, { useState } from 'react';
import {useHistory} from "react-router-dom";

import {
   NavLink as RouterLink,
 } from 'react-router-dom';

import {
   Typography, Button, TextField, Checkbox,
   TableRow, TableCell, TableContainer, Grid, Hidden, Link,
} from '@material-ui/core';
import { DBItem } from '../../model/interface/DBModels';

type Props = {
  index: number,
  item: DBItem,
  quantity: number,
  selectItem: any,
  editItem: any,
  removeItem: any,
  isSelected: boolean,
};

const ItemRow = function(props: Props) {
  let history = useHistory();
  const [quantity, setQty] = useState<number>(props.quantity);
  const [error, setError] = useState<boolean>(quantity === 0);

  const quantityOnChange = (newQuantity: number) => {
      props.editItem(props.index, newQuantity)
      // setQty(newQuantity);
      if(newQuantity === 0) {
         setError(true);
      }
      else {
         setError(false);
      }
  }

   const isNumber = (num: any) => {
   return !(isNaN(num));
   }

   const priceString = () => {
      if(isNumber(props.item.unit) && props.item.unit !== "1") {
         // calculate units
         let initialString = "$" + (props.item.price).toFixed(2) + " / pack of " + props.item.unit
         let unitCost = props.item.price / Number(props.item.unit)
         let unitString = " ($" + (unitCost).toFixed(2) + " / unit)"
         return initialString + unitString
      }
      else {
         return("$" + (props.item.price).toFixed(2) + " / " + props.item.unit)
      }
   }

  return (
      <TableRow>{/* one row = one item */}

         <Hidden xsDown> {/* large version */}
            <TableCell align="center">{/* checkbox */}
               <Checkbox
                  color="primary"
                  aria-label="select item"
                  checked={props.isSelected}
                  onChange={() => props.selectItem(props.index)}
                  inputProps={{'aria-label': 'Select' }}/>
            </TableCell>
            <TableCell component="th" scope="row">{/* item info */}
               <Grid container direction="row" spacing={2}>
                  <Grid item xs={3}>
                     <div className="cart-image-container">
                        <img className="cart-image" src={props.item.image} alt="item"/>
                     </div>
                  </Grid>
                  <Grid item xs>
                     <div className="table-name-cell-container">
                        <div className="table-cell-name">
                           <RouterLink exact to={"/item/" + props.item.exactItemGroupingId} className="nav-color txt-link">
                              <Typography variant="body1">
                                 {props.item.name}
                              </Typography>
                           </RouterLink>
                           <Typography variant="body2">
                              Seller: {props.item.seller} • Price: {priceString()}
                           </Typography>
                        </div>
                     </div>
                  </Grid>
               </Grid>
            </TableCell>
            <Hidden smDown> {/* hide when ssmall */}
               <TableCell align="center">{/* edit/remove */}
                  <Button size="small" onClick={() => props.removeItem(props.index)}>Remove</Button>
               </TableCell>
            </Hidden>
            <TableCell className="quantityCell" align="center">{/* qty field */}
               <TextField
                  id={"filled-number-" + props.index}
                  label="Qty"
                  type="number"
                  error={error}
                  defaultValue={quantity}
                  InputProps={{ inputProps: { min: 1 } }}
                  variant="outlined"
                  size="small"
                  inputProps={{"aria-label": "quantity" }}
                  onChange={(event) => quantityOnChange(Number(event.target.value))}
               /> 
            </TableCell>
            <TableCell align="center">{/* total price */}
               <Typography variant="body2" className="cart-price-text">
                  ${(props.item.price * quantity).toFixed(2)}
               </Typography>
            </TableCell>
         </Hidden>

         <Hidden smUp> {/* small version */}
            <TableCell align="left">{/* checkbox */}
               <Grid container direction="row">
                  <Grid item container xs={2}>
                     <Checkbox
                        aria-label="select item"
                        checked={props.isSelected}
                        onChange={() => props.selectItem(props.index)}
                        inputProps={{'aria-label': 'select item' }}/>
                  </Grid>
                  <Grid item container xs direction="column" spacing={2}>
                     <Grid item container direction="row" xs spacing={2}>
                        <Grid item xs={3}>
                           <div className="cart-image-container">
                              <img className="cart-image" src={props.item.image} alt="item"/>
                           </div>
                        </Grid>
                        <Grid item xs>
                           <RouterLink exact to={"/item/" + props.item.exactItemGroupingId} className="nav-color txt-link">
                              <Typography variant="body1" align="left">
                                 {props.item.name}
                              </Typography>
                           </RouterLink>
                           <Typography variant="body2" align="left">
                              Seller: {props.item.seller}<br/>
                              Price: {priceString()}
                           </Typography>
                        </Grid>
                     </Grid>
                     <Grid item container direction="row" xs spacing={2}>
                        <Grid item xs>
                           <TextField
                              id={"filled-number-" + props.index}
                              label="Qty"
                              type="number"
                              error={error}
                              defaultValue={quantity}
                              InputProps={{ inputProps: { min: 1 } }}
                              variant="outlined"
                              size="small"
                              inputProps={{"aria-label": "quantity" }}
                              onChange={(event) => quantityOnChange(Number(event.target.value))}
                              className="quantityField"
                           />
                        </Grid>
                        <Grid item>
                           <Typography variant="body2" align="right">
                              Item total:
                           </Typography> 
                           <Typography variant="body2" className="cart-price-text" align="right">
                              ${(props.item.price * quantity).toFixed(2)}
                           </Typography> 
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </TableCell>
         </Hidden>

      </TableRow>
   );
}

export default ItemRow;