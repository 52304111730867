import React, { Component } from 'react';

import {AppContext} from '../contexts/AppContext';

import NavBar from './NavBar';


class NavWrap extends Component {

   render() {
      return (
         <AppContext.Consumer>
            {context => (
                  <header className="nav-wrap">
                     <NavBar {...{search: context.searchListener }}/>
                  </header>
            )}
         </AppContext.Consumer>
      );
   }
}

export default NavWrap;
