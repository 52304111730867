import DAO from "./DAO";
import Endpoint from "../request/Endpoint";
import {
   DBExactItemGrouping,
} from "../interface/DBModels";

class ItemDAO extends DAO {
   private readonly itemByIdEndpoint: Endpoint<{}, DBExactItemGrouping>;

   constructor() {
      super();

      this.itemByIdEndpoint = Endpoint.for<{}, DBExactItemGrouping>('item');
   }

   async getItemDetails(token: string = "", itemId: string): Promise<DBExactItemGrouping> {
      const urlParams = {
         token,
         itemId,
      }

      const response = await this.itemByIdEndpoint.get(
         urlParams,
      );

      this.validateResponse(response);
         
      return response;
   }
}

const instance = new ItemDAO();

Object.freeze(instance);

export default instance;