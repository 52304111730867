import React from 'react';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Button
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { DBCard } from '../../../model/interface/DBModels';

type SavedPaymentsProps = {
  paymentList: DBCard[],
  selectedIndex: number,
  setSelectedIndex: any,
  cardFormVisible: boolean,
  toggleFormVisibility: any
};

const SavedPayments = function(props: SavedPaymentsProps) {
  return (
    <div className="saved-addresses">
      {
        props.paymentList.length > 0
        ?
          <div>
            <Typography variant="h6" noWrap gutterBottom={true}>
               Saved Credit Cards
            </Typography>
            <Grid container spacing={2}>
              {props.paymentList.map((dbCard: DBCard, index) => {
                return (
                  <PaymentButton
                    index={index}
                    dbCard={dbCard}
                    setSelectedIndex={props.setSelectedIndex}
                    isSelected={(props.selectedIndex === index)}
                    toggleFormVisibility={props.toggleFormVisibility}
                  />
                )}
              )}
              <Grid item xs>
                <Button
                  variant="outlined"
                  fullWidth={true}
                  color={(props.cardFormVisible) ? "primary" : "default"}
                  onClick={() => props.toggleFormVisibility(!props.cardFormVisible)}
                  style={{height: "100%"}}
                  >
                  Add New Card
                </Button>
              </Grid>
            </Grid>
            <br/>
          </div>
        :
          ""
      }
    </div>
  );
}

export default SavedPayments;

type PaymentButtonProps = {
  index: number,
  dbCard: DBCard,
  setSelectedIndex: any,
  isSelected: boolean,
  toggleFormVisibility: any,
};

const PaymentButton = function(props: PaymentButtonProps) {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Button
        variant="outlined"
        fullWidth={true}
        color={(props.isSelected) ? "primary" : "default"}
        onClick={() => props.setSelectedIndex(props.index)
                        & props.toggleFormVisibility(false)}
        >
          <Grid container justify="space-between" alignItems="center" spacing={2}> 
            <Grid item xs>
              {props.dbCard.name}<br/>
              ••••{props.dbCard.last4Digits} ({props.dbCard.brand})<br/>
              {props.dbCard.expMonth}/{props.dbCard.expYear}
            </Grid>
            {
              (props.isSelected)
              ?
              <Grid item>
                <CheckCircleOutlineIcon fontSize="small" className="closeButton"/>
              </Grid>
              :
                ""
            }
          </Grid>
      </Button>
    </Grid>
  )
}
