type Response     = {
   detail?: string,
   [props: string]: any
};

class DAO {
   protected validateResponse(response: Response) {
      const detail = response.detail;

      if(detail) {
          throw new Error(detail);
      }
  }

}

export default DAO;