import React from "react";
import {
  Typography,
  Link,
  Hidden,
} from "@material-ui/core";

import {
   NavLink as RouterLink
} from "react-router-dom";

const feedbackURL = "https://docs.google.com/forms/d/e/1FAIpQLSd_7MLF8CEXVfN2vSEgLufohyQCCiWLsgbuqT9uFkJ0UwGKQQ/viewform?usp=sf_link";

export default function Footer() {

   const handleFeedback = () => {
      window.open(feedbackURL, "_blank", "noopener,noreferrer")
   }

   return (
      <div className="Footer">
         <Hidden xsDown> {/* large version */}
            <div className="Footer-text">
               <Typography>
                  2022 &copy; <RouterLink exact to="/about-us" className="Footer-link">Assembled Supply</RouterLink>
               </Typography>
            </div>
            <div className="Footer-text">
               <Typography>
                  |
               </Typography>
            </div>
            <div className="Footer-text">
               <Typography>
                  <RouterLink exact to="/privacy-policy" className="Footer-link">
                     Privacy Policy
                  </RouterLink>
               </Typography>
            </div>
            <div className="Footer-text">
               <Typography>
                  |
               </Typography>
            </div>
            <div className="Footer-text" onClick={handleFeedback}>
               <Typography>
                  <RouterLink exact to="/" className="Footer-link">
                     Send us feedback  
                  </RouterLink>
               </Typography>
            </div>
         </Hidden>
         <Hidden smUp> {/* small version */}
            <div className="Footer-text">
               <Typography>
                  2022 &copy; <RouterLink exact to="/about-us" className="Footer-link">Assembled Supply</RouterLink>
               </Typography>
               <Typography>
                  <RouterLink exact to="/privacy-policy" className="Footer-link">
                     Privacy Policy
                  </RouterLink>
               </Typography>
               <Typography onClick={handleFeedback}>
                  <RouterLink exact to="/" className="Footer-link">
                     Send us feedback  
                  </RouterLink>
               </Typography>
            </div>
         </Hidden>
      </div>
   );
}
