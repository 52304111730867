import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Paper, CircularProgress
} from '@material-ui/core';

import {AppContext} from '../../../contexts/AppContext';
import ResultDAO from "../../../model/dao/ResultDAO";
import { DBOrder, DBOrderedItem, DBSearchResults } from '../../../model/interface/DBModels';

import DisplayInfo from '../../orders/DisplayInfo';
import DisplayPayment from '../../orders/DisplayPayment';
import DisplayAddress from '../../orders/DisplayAddress';
import DisplayItems from '../../orders/DisplayItems';
import DisplayPrice from '../../orders/DisplayPrice';

export default function OrderPage() {
  const context = useContext(AppContext);
  const { id } = useParams<{id:string}>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [order, setOrder] = useState<DBOrder>();
  const [orderItems, setOrderItems] = useState<DBSearchResults<DBOrderedItem>>();
  const [orderChanged, setOrderChanged] = useState(false);

  React.useEffect(() => {
    let isActive = true;

    setLoading(true);
    setError(false);
      
    ResultDAO.getOrderItems({}, context.token, id)
    .then((orderItems) => {
        if (isActive) {
          setOrderItems(orderItems);
          setOrder(orderItems.order)
          setLoading(false);
          setError(false);
        }
    }).catch(() => {
        if (isActive) {
            setError(true);
            setLoading(false);
        }
    });

    return () => {
      isActive = false;
    };
  }, [orderChanged, context.token, id]);

  return (
    <Grid item xs> {/* main page */}
      <Paper elevation={3} className="order-history-paper">
        {
          loading
          ?
            <div className="loading-screen">
              <Typography variant="subtitle1" noWrap align="center">
                <CircularProgress size={'1em'}/>
                <br/>
                Loading order...
              </Typography>
            </div>
          :
            (error || (order === undefined))
            ? 
              <div className="loading-screen">
                <Typography variant="subtitle1" noWrap align="center">
                  No order was found matching that ID.
                </Typography>
              </div>
            :
              <Grid container xs={12} spacing={2} justify="flex-end">
                <DisplayInfo 
                  id={order.id}
                  created={order.created}
                  status={order.status}
                  shippingInfo={order.shippingInfo}
                  rejectionReason={order.rejectionReason}
                  setOrderChanged={setOrderChanged}
                  cancellable={true}/>
                <DisplayPayment 
                  last4Digits={order.last4Digits}
                  cardBrand={order.cardBrand}
                  cardName={order.cardName}
                  editable={false}/>
                <DisplayAddress 
                  title="Shipping Address" 
                  shippingCity={order.shippingCity}
                  shippingCountry={order.shippingCountry}
                  shippingLine1={order.shippingLine1}
                  shippingLine2={order.shippingLine2}
                  shippingPostalCode={order.shippingPostalCode}
                  shippingState={order.shippingState}
                  shippingName={order.shippingName}
                  shippingPhone={order.shippingPhone}
                  editable={false}/>
                <DisplayItems 
                  items={orderItems?.results}
                  status={order.status}/>
                <DisplayPrice 
                  order={order}/>
              </Grid>
        }
      </Paper>
    </Grid>
  );
}
