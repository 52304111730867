import DAO from "./DAO";
import Endpoint from "../request/Endpoint";

type ItemInfo = {
   quantity?: number;
   itemId: string;
}

type Qty = {
   quantity: number;
}

type Guest = {
   guestId: string;
}

class CartDAO extends DAO {
   private readonly addItemsEndpoint: Endpoint<ItemInfo[], {}>;
   private readonly clearEndpoint: Endpoint<{}, {}>;
   private readonly updateQtyEndpoint: Endpoint<Qty, {}>;
   private readonly removeItemsEndpoint: Endpoint<ItemInfo[], {}>;
   private readonly copyEndpoint: Endpoint<Guest, {}>;

   constructor() {
      super();

      this.addItemsEndpoint = Endpoint.for<ItemInfo[], {}>('cartAddClear');
      this.clearEndpoint = Endpoint.for<{}, {}>('cartAddClear');
      this.updateQtyEndpoint = Endpoint.for<Qty, {}>('cartById');
      this.removeItemsEndpoint = Endpoint.for<ItemInfo[], {}>('cartDelete');
      this.copyEndpoint = Endpoint.for<Guest, {}>('cartCopy');
   }

   async addItems(token: string = "", items: ItemInfo[]) {
      console.log("adding item to cart");

      const urlParams = {
         token,
      }

      const response = await this.addItemsEndpoint.post(
         items,
         urlParams,
      );

      this.validateResponse(response);
   }

   async clearCart(token: string = "") {
      console.log("clearing cart");
      const urlParams = {
         token,
      }

      const response = await this.clearEndpoint.delete(urlParams);

      if (response) {
         this.validateResponse(response);
      }
   }

   async updateQty(newQty:Qty, token: string = "", itemId: string) {
      console.log("updating qty of item: " + itemId + "to : " + newQty.quantity);
      const urlParams = {
         token,
         itemId,
      }

      const response = await this.updateQtyEndpoint.put(
         newQty,
         urlParams,
      );

      if (response) {
         this.validateResponse(response);
      }
   }

   async removeItems(token: string = "", items: ItemInfo[]) {
      console.log("removing items from cart");
      const urlParams = {
         token,
      }

      const response = await this.removeItemsEndpoint.post(items, urlParams);

      if (response) {
         this.validateResponse(response);
      }
   }

   async copyItems(token: string = "", guest: Guest) {
      console.log("copying items from guestcart");
      const urlParams = {
         token,
      }

      const response = await this.copyEndpoint.post(guest, urlParams);

      if (response) {
         this.validateResponse(response);
      }
   }
}

const instance = new CartDAO();

Object.freeze(instance);

export default instance;