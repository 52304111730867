import Endpoint from "../request/Endpoint";
import DAO from "./DAO";
import {Credentials, Login, NewAccount, UpdatedAccount, Account} from "../interface/DBModels";

class UserDAO extends DAO {
   private readonly loginEndpoint: Endpoint<Login, Credentials>;
   private readonly guestEndpoint: Endpoint<{}, Credentials>;
   private readonly accountEndpoint: Endpoint<{}, Account>;
   private readonly signupEndpoint: Endpoint<NewAccount, Credentials>;
   private readonly updateEndpoint: Endpoint<UpdatedAccount, Credentials>;
   private readonly deleteEndpoint: Endpoint<{}, {}>;

   constructor() {
      super();

      this.loginEndpoint = Endpoint.for<{}, Credentials>('login');
      this.guestEndpoint = Endpoint.for<{}, Credentials>('guestAccount');
      this.accountEndpoint = Endpoint.for<{}, Account>('account');
      this.signupEndpoint = Endpoint.for<NewAccount, Credentials>('account');
      this.updateEndpoint = Endpoint.for<UpdatedAccount, Credentials>('account');
      this.deleteEndpoint = Endpoint.for<{}, {}>('account');
   }
   
   async login(email: string, password: string): Promise<Credentials> {
      const data = {email, password};
      
      const response = await this.loginEndpoint.post(data);
      
      this.validateResponse(response);
      
      return { token: response.token, account: response.account };
   }

   async guestLogin(): Promise<Credentials> {
      
      const response = await this.guestEndpoint.post({});
      
      this.validateResponse(response);
      
      return { token: response.token, account: response.account };
   }

   async getAccountInfo(token: string = ""): Promise<Account> {

      const response = await this.accountEndpoint.get(
         {token},
      );

      this.validateResponse(response);

      return response;
   }

   async signup(newUser: NewAccount): Promise<Credentials> {

      const response = await this.signupEndpoint.post(newUser);

      this.validateResponse(response);

      return { token: response.token, account: response.account };
   }

   async updateAccount(updatedUser: UpdatedAccount): Promise<Credentials> {

      const response = await this.updateEndpoint.post(updatedUser);

      this.validateResponse(response);

      return { token: response.token, account: response.account };
   }

   async deleteAccount(): Promise<void> {
      const response = await this.deleteEndpoint.delete();

      if (response) {
         this.validateResponse(response);
      }
   }
}

const instance = new UserDAO();

Object.freeze(instance);

export default instance;