import React from 'react';

import {
  NavLink as RouterLink,
} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Link
} from '@material-ui/core';

type Props = {
  last4Digits: string,
  cardBrand: string,
  cardName: string,
  editable: boolean
};

export default function DisplayPayment(props: Props) {
   return (
     <Grid item xs className="order-grid-item">
       <Typography variant="h6" display="inline" noWrap gutterBottom={true}>
          Payment Method
        </Typography>
        {
         (props.editable === true)
         ?
          <Link component={RouterLink} to={`/checkout/payment`} variant="body2" display="inline" className="inline-edit-button">
          (Edit)
          </Link>
         :
            ""
       }
       <Grid item>
         <Typography variant="body1">
          ••••{props.last4Digits} ({props.cardBrand})
         </Typography>
         <Typography variant="body2">
          {props.cardName}
         </Typography>
       </Grid>
     </Grid>
   );
}
