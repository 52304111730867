import React, { ChangeEvent } from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

type Props = { 
   inputArray: string[],
   selected: any,
   defaultValue?: any,
   multiple?: boolean,
 };

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function DropDown(props:Props) {
    // Hook + setHook - Basically a state
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<string[]>([]);
    const loading = open && options.length === 0;

    // Do this on render + update
    React.useEffect(() => {
        let active = true;
        if(!loading) { return undefined;}

        // Options Retrieval (TODO)
        if(active) {
            setOptions(props.inputArray)
        }

        return () => { active = false;};
    }, [loading]);

    React.useEffect(() => {
        if(!open) { setOptions([]); }
    }, [open]);

    const createOption = () => {
        if (props.multiple) {
            return (
                (option:any, {selected}:any) => (
                    <React.Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ padding: 1, margin: 2 }}
                            checked={selected}
                            />
                        {option}
                    </React.Fragment>
                )
              );
        } else {
            return undefined;
        }
    }

    return (
        <Autocomplete
            size="small"
            multiple={props.multiple}
            disableCloseOnSelect={props.multiple}
            open={open}
            onOpen={() => {setOpen(true);}}
            onClose={() => {setOpen(false);}}
            getOptionSelected={(option, value) => option === value}
            getOptionLabel={(option) => option}
            renderOption={createOption()}
            options={options}
            loading={loading}
            value={props.defaultValue}
            onChange={(event: ChangeEvent<{}>, value: string | null) => props.selected(value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    className="filter-input"
                    variant="outlined"
                    margin="dense"
                    InputProps={{
                        ...params.InputProps, endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )}}
                />
            )}
        />
    );
}