import React, { useState, useContext} from 'react';
import {
   NavLink as RouterLink
} from "react-router-dom";

import {
   MenuItem,
   MenuList,
   Button,
   ClickAwayListener,
   Paper,
   Popper,
   Grow
} from '@material-ui/core';

import AccountCircle from '@material-ui/icons/AccountCircle';

import {AppContext} from "../../contexts/AppContext";

export default function AccountMenu() {
   const [open, setOpen] = useState(false);
   const anchorRef = React.useRef<HTMLButtonElement>(null);

   const context = useContext(AppContext);

   const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
   };

   const handleSignOut = () => {
      context.signOut();
      handleClose();
   }
  
    const handleClose = () => {
      setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    }
  
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current!.focus();
      }
  
      prevOpen.current = open;
    }, [open]);

   return (
      <div onMouseEnter={handleToggle} onMouseLeave={handleToggle}>
         <RouterLink exact to="/settings" className="color-white remove-link">
            <Button
               aria-label="account of current user"
               aria-controls={open ? 'menu-list-grow' : undefined}
               aria-haspopup="true"
               color="inherit"
               startIcon={<AccountCircle />}
               ref={anchorRef}
            >
               My Account
            </Button>
         </RouterLink>
         <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            className="account-menu"
         >
            {({ TransitionProps, placement }) => (
               <Grow
               {...TransitionProps}
               style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
               >
                  <Paper>
                     <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                           id="menu-appbar"
                           autoFocusItem={open}
                           onKeyDown={handleListKeyDown}
                        >
                           <MenuItem 
                              onClick={handleClose}
                           >
                              <RouterLink exact to="/orders" className="nav-color">
                                 Order History
                              </RouterLink>
                           </MenuItem>
                           <MenuItem
                              onClick={handleClose}
                           >
                              <RouterLink exact to="/settings" className="nav-color">
                                 Settings
                              </RouterLink>
                           </MenuItem>
                           <MenuItem
                              onClick={handleSignOut}
                           >
                              <RouterLink to="/" className="nav-color">
                                 Sign Out
                              </RouterLink>
                           </MenuItem>
                        </MenuList>
                     </ClickAwayListener>
                  </Paper>
               </Grow>
            )}
         </Popper>
      </div>
   )
};