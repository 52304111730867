import React from 'react';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Button
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { DBAddress } from '../../../model/interface/DBModels';

type SavedAddressesProps = {
  addressList: DBAddress[],
  selectedIndex: number,
  setSelectedIndex: any,
  addressFormVisible: boolean,
  toggleFormVisibility: any
};

const SavedAddresses = function(props: SavedAddressesProps) {
  return (
    <div className="saved-addresses">
      {
        props.addressList.length > 0
        ?
          <div>
            <Typography variant="h6" noWrap gutterBottom={true}>
               Saved Addresses
            </Typography>
            <Grid container spacing={2}>
              {props.addressList.map((dbAddress: DBAddress, index) => {
                return (
                  <AddressButton
                    index={index}
                    dbAddress={dbAddress}
                    setSelectedIndex={props.setSelectedIndex}
                    isSelected={(props.selectedIndex === index)}
                    toggleFormVisibility={props.toggleFormVisibility}
                  />
                )}
              )}
              <Grid item xs>
                <Button
                  variant="outlined"
                  fullWidth={true}
                  color={(props.addressFormVisible) ? "primary" : "default"}
                  onClick={() => props.toggleFormVisibility(!props.addressFormVisible)}
                  style={{height: "100%"}}>
                  Add New Address
                </Button>
              </Grid>
            </Grid>
            <br/>
          </div>
        :
          ""
      }
    </div>
  );
}

export default SavedAddresses;

type AddressButtonProps = {
  index: number,
  dbAddress: DBAddress,
  setSelectedIndex: any,
  isSelected: boolean,
  toggleFormVisibility: any,
};

const AddressButton = function(props: AddressButtonProps) {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Button
        variant="outlined"
        fullWidth={true}
        color={(props.isSelected) ? "primary" : "default"}
        onClick={() => props.setSelectedIndex(props.index)
                        & props.toggleFormVisibility(false)}
        >
          <Grid container justify="space-between" alignItems="center" spacing={2}> 
            <Grid item xs>
              {props.dbAddress.name}<br/>
              {props.dbAddress.line1} {props.dbAddress.line2}<br/>
              {props.dbAddress.city}, {props.dbAddress.state} {props.dbAddress.postalCode}
            </Grid>
            {
              (props.isSelected)
              ?
              <Grid item>
                <CheckCircleOutlineIcon fontSize="small" className="closeButton"/>
              </Grid>
              :
                ""
            }
          </Grid>
      </Button>
    </Grid>
  )
}
