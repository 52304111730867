import { Grid, Typography, Divider, Paper, Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';

export default function PageNotFoundPage() {
  let history = useHistory();
 
  return (
    <div className="page-offset account-page">
      <Grid container item justify="center" xs={12} spacing={2}> {/* main page */}
        {/* <Grid item xs={12}>
          <Typography variant="h5" noWrap>
             404: Page Not Found
          </Typography>
        </Grid> */}

        <Grid item xs> {/* main page */}
          <Paper elevation={3} className="order-history-paper">
            <div className="loading-screen">
              <Typography variant="h1" noWrap align="center">
                404
              </Typography>
              <Typography variant="subtitle1" noWrap align="center">
                Page Not Found
              </Typography>
              <Typography variant="body1" noWrap align="center">
                The page you are looking for was not found.
                <Button color="primary" onClick={() => {history.push("/")}}>
                  Go Home
                </Button>
              </Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
