import React, { useState, useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Paper, CircularProgress, Divider
} from '@material-ui/core';

import ResultDAO from "../../../model/dao/ResultDAO";
import {AppContext} from '../../../contexts/AppContext';
import { DBOrder, DBOrderedItem, DBSearchResults } from '../../../model/interface/DBModels';

import DisplayInfo from '../../orders/DisplayInfo';
import DisplayPayment from '../../orders/DisplayPayment';
import DisplayAddress from '../../orders/DisplayAddress';
import DisplayItems from '../../orders/DisplayItems';
import MessageBanner from '../../MessageBanner';

type Props = {
  orderId: string,
}

export default function ConfirmationForm(props: Props) {
   const context = useContext(AppContext);

   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(false);

   const [order, setOrder] = useState<DBOrder>();
   const [orderItems, setOrderItems] = useState<DBSearchResults<DBOrderedItem>>();

   React.useEffect(() => {
     let isActive = true;

     setLoading(true);
     setError(false);
 
     ResultDAO.getOrderItems({}, context.token, props.orderId)
     .then((orderItems) => {
         if (isActive) {
           setOrderItems(orderItems);
           setOrder(orderItems.order)
           setLoading(false);
           setError(false);
         }
     }).catch(() => {
         if (isActive) {
             setError(true);
             setLoading(false);
         }
     });

       return () => {
         isActive = false;
       };
   }, [context.token, props.orderId]);


   return (
      (loading)
      ?
      <div className="loading-screen">
        <Typography variant="subtitle1" noWrap align="center">
          <CircularProgress size={'1em'}/>
          <br/>
          Submitting order...
        </Typography>
      </div>
      :
     <div> {/* outer wrapper */}
      {
        (error || (order === undefined))
        ?
          <MessageBanner
              messageType={"error"}
              messageHeader={"Something went wrong."}
              messageContent={"Please return to the homepage and try checking out again."}/>
        :
          <MessageBanner
            messageType={"success"}
            messageHeader={"Thank you for ordering from Assembled Supply!"}
            messageContent={"We will send you an email with your confirmation details shortly. Your order is currently processing."}/>
      }
       <br />
        <Grid item xs={12}> {/* title */}
          <Typography variant="h5" noWrap>
             Order Information
          </Typography>
        </Grid>
        <Paper elevation={3}> {/* info */}
          {
            (error || (order === undefined))
            ?
              <div className="loading-screen">
                <Typography variant="subtitle1" noWrap align="center">
                  An error has occurred.
                </Typography>
              </div>
            :
            <Grid container xs={12} className="checkout-form-inner">
              <DisplayInfo
                id={order.id}
                created={order.created}
                status={order.status}
                shippingInfo={order.shippingInfo}
                rejectionReason={order.rejectionReason}
                cancellable={false}/>
              <Grid item xs={12} className="padded-divider">
                <Divider />
              </Grid>
              <DisplayPayment
                last4Digits={order.last4Digits}
                cardBrand={order.cardBrand}
                cardName={order.cardName}
                editable={false}/>
              <DisplayAddress
                title="Shipping Address"
                shippingCity={order.shippingCity}
                shippingCountry={order.shippingCountry}
                shippingLine1={order.shippingLine1}
                shippingLine2={order.shippingLine2}
                shippingPostalCode={order.shippingPostalCode}
                shippingState={order.shippingState}
                shippingName={order.shippingName}
                shippingPhone={order.shippingPhone}
                editable={false}/>
              <Grid item xs={12} className="padded-divider">
                <Divider />
              </Grid>
              <DisplayItems
                items={orderItems?.results}
                status="Pending"/>
            </Grid>
          }
        </Paper>
     </div>
   );
}
