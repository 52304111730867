import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import {
   Typography, FormControl, InputLabel, Select, MenuItem, TextField
} from '@material-ui/core';
import { DBAddress } from '../../../model/interface/DBModels';
import CheckoutTextField from "../CheckoutTextField";
import DropDown from '../../../pages/search/components/DropDown';
import { Autocomplete } from '@material-ui/lab';
import {CountryType, countries} from '../../_helpers/CountryType';
import {isValidEmailAddress} from '../../_helpers/EmailChecker';

type Props = {
  title: string,
  isGuestAccount: boolean,
  accountEmail: string,
  currentlySelectedAddress: DBAddress | undefined,
}

const AddressInput = function(props: Props) {

  React.useEffect(() => {
    // if(props.selectedIndex === -1 && props.addressFormVisible) {
      savedAddressFiller(props.currentlySelectedAddress)
    // }
  }, [props.currentlySelectedAddress]);

   // states for the form
  const [name, setName] = useState<string>("");
  const [address1, setAddress1] = useState<string>("");
  const [address2, setAddress2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [country, setCountry] = useState<string>("US");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  // error states for the form
  const [nameError, setNameError] = useState<boolean>(false);
  const [address1Error, setAddress1Error] = useState<boolean>(false);
  const [cityError, setCityError] = useState<boolean>(false);
  const [regionError, setRegionError] = useState<boolean>(false);
  const [zipCodeError, setZipCodeError] = useState<boolean>(false);
  const [countryError, setCountryError] = useState<boolean>(false);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  // handlers to change info
  const generalHandler = (event: React.ChangeEvent<HTMLInputElement>, stateSetter: (value: string) => void, errorSetter?: (value: boolean) => void) => {
    let value = event.target.value;
    if(errorSetter !== undefined) {
      if(value.length === 0) { errorSetter(true); }
      else { errorSetter(false); }
    }
    stateSetter(value);
  }

  const emailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    if(value.length === 0) { 
      setEmailError(true); 
    }
    else { 
      if(isValidEmailAddress(value)) { // good email
        setEmailError(false); 
      }
      else {
        setEmailError(true);
      }
    }
    setEmail(value);
  }

  // const countryHandler = (event: React.ChangeEvent<{}>, value: string) => {
  //   let code = countries.find((c: CountryType) => c.label === value)?.code;
  //   setCountry(code as string);
  // };

  const countryHandler = (event: React.ChangeEvent<{}>, value: CountryType | null) => {
    let result = countries.find((c: CountryType) => c.code === country);
    if(value?.label !== undefined) {
      setCountry(value?.code)
    }
    else {
      setCountry("")
    }
  };

  // set address if a saved one is selected
  const savedAddressFiller = (dbAddress?: DBAddress) => {
    if(dbAddress !== undefined) {
      setCity(dbAddress.city);
      setCountry(dbAddress.country);
      setAddress1(dbAddress.line1);
      setAddress2(dbAddress.line2);
      setZipCode(dbAddress.postalCode);
      setRegion(dbAddress.state);
      setName(dbAddress.name);
      setPhoneNumber(dbAddress.phone);
      setEmail(props.accountEmail);
    }
    else {
      setCity("");
      setCountry("");
      setAddress1("");
      setAddress2("");
      setZipCode("");
      setRegion("");;
      setName("");
      setPhoneNumber("");
      setEmail("");
    }
  };

   return (
     <div> {/* outer wrapper */}
      <Typography variant="h6" noWrap gutterBottom={true}>
         {props.title}
      </Typography>
      <Grid container spacing={2}>
        <CheckoutTextField size={8}
          name="name" label="Full Name" placeholder="Full Name" autocomplete="name" value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => generalHandler(event, setName, setNameError)}
          isError={nameError} errorMessage={"Full name is required."}/>
        <CheckoutTextField size={4}
          name="phone" label="Phone" placeholder="Phone" autocomplete="tel" value={phoneNumber}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => generalHandler(event, setPhoneNumber, setPhoneNumberError)}
          isError={phoneNumberError} errorMessage={"Phone number is required."}/>
          {
            (props.isGuestAccount === true)
            ? 
              <CheckoutTextField size={12}
                name="email" label="Email Address" placeholder="Email" autocomplete="email" value={email} onChange={(event: React.ChangeEvent<HTMLInputElement>) => emailHandler(event)}
                isError={emailError} errorMessage={"A valid email address is required."}/>
            : ""
          }
        <CheckoutTextField size={12}
          name="address-line-1" label="Address Line 1" placeholder="Street address, P.O. box, company name, c/o" autocomplete="address-line1" value={address1}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => generalHandler(event, setAddress1, setAddress1Error)}
          isError={address1Error} errorMessage={"Address Line 1 is required."}/>
        <CheckoutTextField size={12}
          name="address-line-2" label="Address Line 2" placeholder="Apartment, suite, unit, building, floor, etc" autocomplete="address-line2"  value={address2}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => generalHandler(event, setAddress2)} required={false}/>
        <CheckoutTextField size={6}
          name="city" label="City" placeholder="City" autocomplete="address-level2" value={city}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => generalHandler(event, setCity, setCityError)}
          isError={cityError} errorMessage={"City is required."}/>
        <CheckoutTextField size={6}
          name="region" label="State / Province / Region" placeholder="State / Province / Region" autocomplete="address-level1" value={region}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => generalHandler(event, setRegion, setRegionError)}
          isError={regionError} errorMessage={"State is required."}/>
        <CheckoutTextField size={6}
          name="zip-code" label="Zip Code" placeholder="Zip Code" autocomplete="postal-code" value={zipCode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => generalHandler(event, setZipCode, setZipCodeError)}
          isError={zipCodeError} errorMessage={"Zip Code is required."}/>
        <Grid item xs={12} md>
            <Autocomplete
              options={countries as CountryType[]}
              getOptionLabel={(option) => option.label}
              value={countries.find((c: CountryType) => c.code === country)}
              onChange={countryHandler}
              aria-label="Country"
              renderOption={(option) => (
                <div>
                  {option.label}
                </div>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={true}
                  name="country"
                  label="Country"
                  placeholder="Country"
                  autoComplete="country-name"

                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  margin='dense'

                  inputProps={{
                    ...params.inputProps,
                    "aria-label": "Country"
                  }}
                />
              )}
            />
        </Grid>
      </Grid>
     </div>
   );
}

export default AddressInput;
