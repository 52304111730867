import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Grid, Typography, Card, Button, TextField, Hidden, CircularProgress } from '@material-ui/core';
import toolboxIcon from '../../assets/AS_icon_toolbox.svg';
import personIcon from '../../assets/AS_icon_person.svg';
import lightbulbIcon from '../../assets/AS_icon_lightbulb.svg';

import CategoryButton from '../../navigation/components/CategoryButton';
import { AppContext } from '../../contexts/AppContext';
import { getQueryParamString } from "../../components/_helpers/UrlHelper";
import ResultDAO from "../../model/dao/ResultDAO";
import { DBAbstractItem } from '../../model/interface/DBModels';


type Event = {
   target: {
      value: string
   }
};

type NavCard = {
   name: string,
   icon: any,
   iconName: string,
   pluralName: string,
   pathName: string,
}

export default function LandingPage() {
   const cardInfo: NavCard[] = [
      {
         name: "Category",
         icon: toolboxIcon,
         iconName: "toolbox icon",
         pluralName: "Categories",
         pathName: "/categories",
      },
      {
         name: "Trade",
         icon: personIcon,
         iconName: "person icon",
         pluralName: "Trades",
         pathName: "/trades",
      },
      {
         name: "Project",
         icon: lightbulbIcon,
         iconName: "lightbulb icon",
         pluralName: "Projects",
         pathName: "/categories",
      },
   ];

   const [searchText, setSearchText] = useState('');
   const [showSuggestions, setShowSuggestions] = useState(false)
   const [recentSearch, setrecentSearch] = useState<Array<string>>([])
   const [searchResult, setSearchResult] = useState<DBAbstractItem[]>([])
   const [loader, setLoader] = useState(false)
   const [recentPurchase, setRecentPurchase] = useState<DBAbstractItem[]>([])
   const [recentUserSearch, setRecentUserSearch] = useState<DBAbstractItem[]>([])


   let history = useHistory();

   const context = useContext(AppContext);

   const handleChange = (evt: Event) => {
      const searchText = evt.target.value;
      setSearchText(searchText);
   };

   const selectRecents = (text: string) => {
      setSearchText(text)
      setShowSuggestions(false)
   }

   const saveRecentSearch = (text: string) => {
      if (text == null || text == '')
         return

      if (recentSearch.indexOf(text) != -1)
         return
      const jsonParsed = JSON.stringify([text].concat(recentSearch))
      localStorage.setItem("AS_MOST_RECENT_SEARCHES", jsonParsed);
   }
   const doSearch = () => {
      console.log("setting search text L: " + searchText);
      context.setSearchText(searchText);
      saveRecentSearch(searchText)
      context.searchListener.clearQueryParams();
      context.searchListener.updateText(searchText);
      history.push({
         pathname: "/search",
         search: getQueryParamString(context.searchListener.getQueryParams()),
      });
   };

   const goToCategories = () => {
      history.push({
         pathname: "/categories",
      });
   }

   const goToBOM = () => {
      history.push({
         pathname: "/bom",
      });
   }

   const handleClick = (pathName: string) => {
      return (() => history.push(pathName));
   }

   const checkForSubmit = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
         doSearch();
      }
   };

   const searchItems = () => {
      setLoader(true)
      const text = searchText
      ResultDAO.searchItems({ searchText: [text], getLogs: ["yes"] }, context.token)
         .then((categories) => {
            if (searchText == text) {
               setSearchResult(categories.results)
               setRecentPurchase(categories.purchase_history)
               setRecentUserSearch(categories.recent_searches)
               setLoader(false)
            }
         }).catch(() => {
            setSearchResult([])
            setLoader(false)
         });
   }

   const redirectToItem = (id: string) => {
      history.push("/item/" + id);
   }

   React.useEffect(() => {
      if (searchText != "")
         searchItems()
   }, [searchText])

   React.useEffect(() => {
      const recenthistory = localStorage.getItem("AS_MOST_RECENT_SEARCHES")
      if (recenthistory != null && recenthistory != '')
         setrecentSearch(JSON.parse(recenthistory))
   }, [localStorage.getItem("AS_MOST_RECENT_SEARCHES")])

   const filteredRecentSearches = recentSearch.filter(s => s.toUpperCase().includes(searchText.toUpperCase()))

   console.log("account", context.token == "")

   const suggestionsDropdown = (recentSearches: string[]) => (
      <>
         {showSuggestions &&
            <div className="recent-searches-tab">
               <Box
                  sx={{ width: "50%" }}
               >
                  <Box className="recent-searches-label">
                     <Typography className="recent-search-text">
                        Recent Searches
                     </Typography>
                     <div className="recent-search-divider"></div>
                  </Box>
                  <Box sx={{ minHeight: "100px" }}>
                     {searchText != "" && loader && context.token != "" && <CircularProgress size={"2rem"} />}
                     {/* {!loader && searchText != "" && recentUserSearch && recentUserSearch.length == 0 && <Typography className="recent-search-text">(Empty)</Typography>} */}
                     {!loader && searchText != "" && context.token != "" && recentUserSearch && recentUserSearch.slice(0, 5).map(searchText => {
                        return <div className="recent-search-card" onClick={() => selectRecents(String(searchText))}>{searchText}</div>
                     })}
                     {context.token == "" && recentSearches && recentSearches.slice(0, 5).map(searchText => {
                        return <div className="recent-search-card" onClick={() => selectRecents(String(searchText))}>{searchText}</div>
                     })}
                  </Box>
                  <Box className="recent-searches-label">
                     <Typography className="recent-search-text">
                        Suggested items {"&"} Categories
                     </Typography>
                     <div className="recent-search-divider"></div>
                  </Box>
                  <Box sx={{ marginTop: "10px", minHeight: "100px" }}>
                     {searchText != "" && loader && <CircularProgress size={"2rem"} />}
                     {!loader && searchText != "" && searchResult && searchResult.slice(0, 5).map(searchText => {
                        return <div className="recent-search-card" onClick={() => { redirectToItem(searchText.id) }}>{searchText.name}</div>
                     })}
                  </Box>
               </Box>
               <Box
                  sx={{
                     width: "50%",
                     borderLeft: "1px solid #00000042",
                     marginLeft: "10px",
                     paddingLeft: "10px"
                  }}
               >
                  <Box className="recent-searches-label">
                     <Typography className="recent-search-text">
                        Recently purchased items
                     </Typography>
                     <div className="recent-search-divider"></div>
                  </Box>
                  {searchText != "" && loader && <CircularProgress size={"2rem"} />}
                  {!loader && searchText != "" && recentPurchase && recentPurchase.slice(0, 5).map(searchText => {
                     return <div className="recent-search-card" onClick={() => { redirectToItem(searchText.id) }}>{searchText.name}</div>
                  })}
               </Box>
            </div>}
      </>
   )


   return (
      <div className="landing-page page-offset" >
         {showSuggestions && <div className="recent-searches-tab-hidden" onClick={() => setShowSuggestions(false)} ></div>}
         <Box
            className="" // {/* "lg-p-top" + classes.wrapper */}
            display="flex"
            justifyContent="center"
         >
            <div className="" /* {classes.contentWrapper} */>
               <Grid container spacing={6}>
                  <Grid container item xs={12} className="background">
                     <Grid item xs={12}>
                        <div className="spacer-small"></div>
                        <Typography variant="h3" className="landing-text">
                           Find parts faster and cheaper
                        </Typography>
                        <div className="spacer"></div>
                     </Grid>

                     <Grid item xs={12} className="search-area">
                        <Hidden xsDown> {/* large version */}
                           <div className="landing-btn">
                              <CategoryButton childClassName="landing-category-btn" />
                           </div>
                        </Hidden>
                        <div className="search-bar">
                           <TextField
                              variant="outlined"
                              value={searchText}
                              onKeyDown={checkForSubmit}
                              onChange={handleChange}
                              onFocus={() => setShowSuggestions(true)}
                              placeholder="What are you looking for today?"
                              className="input-root input-input"
                              inputProps={{ 'className': 'search-bar-input', 'aria-label': 'search' }}
                              InputProps={{
                                 className: "search-bar-input",
                              }}
                           />
                           {suggestionsDropdown(filteredRecentSearches)}
                        </div>
                        <div className="landing-btn">
                           <Button variant="contained" color="primary" className="search-btn" onClick={doSearch}>
                              Search
                           </Button>
                        </div>
                     </Grid>
                     <Grid item xs={12}>
                        <div></div>
                     </Grid>

                     <Hidden xsDown> {/* large version */}
                     </Hidden>

                     <Grid item xs={12}>
                        <Hidden smUp> {/* small version */}
                           <Typography variant="body1" paragraph className="bom-text">
                              Want to view everything? <button className="underlined-clickable bom-text" onClick={goToCategories}>
                                 View all categories
                              </button>
                           </Typography>
                        </Hidden>
                        <Typography variant="body1" paragraph className="bom-text">
                           Already have a list? <button className="underlined-clickable bom-text" onClick={goToBOM}>
                              Search by BOM
                           </button>
                        </Typography>
                        <div className="spacer-large"></div>
                     </Grid>
                  </Grid>
                  {cardInfo.map((info, index) => (
                     <Grid key={index} item md={4} xs={12}>
                        <Card className="info-card" elevation={3}>
                           <Box pt={5} pr={5} pl={5} pb={5}>
                              <Typography variant="h6">
                                 Shop By
                              </Typography>
                              <Typography variant="h4" className="info-card-name">
                                 <b>{info.name}</b>
                              </Typography>
                              <div className="info-card-img">
                                 <img height="160" width="160" src={info.icon} alt={info.iconName} />
                              </div>
                              <Button size="large" color="primary" variant="contained" onClick={handleClick(info.pathName)}>
                                 Browse All {info.pluralName}
                              </Button>
                           </Box>
                        </Card>
                     </Grid>
                  ))}
                  <Grid item xs={12} />
               </Grid>
            </div>
         </Box>
      </div>
   );
}