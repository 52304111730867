import React, { useState, ChangeEvent, MouseEvent } from 'react';

import {
   Button,
   TextField,
   IconButton,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

type Props = {
   title: string,
   handleSaveTitle: (newTitle:string) => void,
   handleCancelEdit: () => void,
}

export default function EditTitleDisplay(props:Props) {
   const {title, handleSaveTitle, handleCancelEdit} = props;
   const [newTitle, setNewTitle] = useState(title);
   const [canSave, setCanSave] = useState(true);

   const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setNewTitle(event.target.value);
      if (event.target.value === "") {
         setCanSave(false);
      } else {
         setCanSave(true);
      }
   }

   return (
      <div className="u-flexItem u-centerVerticalRelative">
         <div className="u-marginVertical-1 u-marginRight-1">
            <TextField value={newTitle} onChange={handleTitleChange} label="Project Name" variant="filled"/>
         </div>
         <div className="u-marginVertical-1 u-marginRight-1 u-centerVerticalRelative">
            <Button
               variant="contained"
               color="primary"
               size="small"
               startIcon={<SaveIcon />}
               disabled={!canSave}
               onClick={() => handleSaveTitle(newTitle)}
               >
               Save
            </Button>
         </div>
         <div className="u-marginVertical-1 u-centerVerticalRelative">
               <IconButton color="primary" aria-label="cancel editing project name" onClick={handleCancelEdit}>
                  <CloseIcon/>
               </IconButton>
         </div>
      </div>
   );
}