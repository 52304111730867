import React, {useState} from 'react';

import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

import SignUp from '../../login/SignUp';
import LoginHelper from '../../login/LoginHelper';

const customTheme = createMuiTheme({
   palette: {
      primary: {
         main: "#156cf0",
      },
      secondary: {
         main: "#08335E",
      },
   },
});

type Props = DialogProps & {
   isSignUp: boolean,
   handleClose: () => void,
};

export default function LoginDialog(props: Props) {
   const {className, isSignUp, handleClose, ...rest} = props;

   const [onSignUp, setOnSignUp] = useState<boolean>(isSignUp);

   const showSignUp = () => {
      setOnSignUp(!onSignUp);
   }

   return (
      <ThemeProvider theme={customTheme}>
         <div>
            <Dialog
            className={className}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth={true}
            onBackdropClick={handleClose}
            onEscapeKeyDown={handleClose}
            {...rest}
            >
            <DialogTitle id="form-dialog-title">
               {onSignUp ? "Sign Up" : "Log In"}
            </DialogTitle>
            <DialogContent>
               {onSignUp ?
                  <SignUp showSignUp={showSignUp} closeCb={handleClose}/>
                  :
                  <LoginHelper showSignUp={showSignUp} closeCb={handleClose}/>
               }
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose} color="primary">
                  Cancel
               </Button>
            </DialogActions>
            </Dialog>
         </div>
      </ThemeProvider>
   );
}