import React from 'react';
import {useDropzone} from 'react-dropzone';
import { Paper, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

type Props = {
   dropCb: (<T extends File>(acceptedFiles: T[]) => void)
}

export default function BOMUpload(props: Props) {

   const {getRootProps, getInputProps} = useDropzone({
      maxSize: 16384000,
      multiple: false,
      onDrop: props.dropCb,
      accept: ".txt, .csv, .xls, .xlsx"
   });

   return (
      <Paper className="u-margin-2">
         <div className="u-margin-1 u-paddingTop-1">
            <section className="container">
               <div {...getRootProps({className: 'dropzone'})}>
                  <CloudUploadIcon/>
                  <input {...getInputProps()} />
                  <p>Drag and drop, or click, to select a file</p>
               </div>
            </section>
         </div>
         <div className="u-margin-1">
            <Typography>
               Upload a csv list of item descriptions to search for.  Your
               searches will appear below. Total upload is limited by your browser cache.
               If you encounter issues uploading large files (8MBs+),
               try clearing your cache first and retrying the upload.
            </Typography>
         </div>
      </Paper>
   );
}