import {useState} from "react";
import { buildDefaultQueryParams } from "../../model/interface/ModelBuilder";
import { QueryParamsModel } from "../../model/interface/Models";

import {QueryParamHook} from "../hook";

/**
 * Creates a hook for maintaining query params,
 * updating them incrementally, and resetting them.
 */
export default function useQueryParams(): QueryParamHook {
  const [queryParams, setQueryParams] = useState(buildDefaultQueryParams());

  return {
    queryParams,
    updateQueryParams(params: Partial<QueryParamsModel>) {
      setQueryParams({...queryParams, ...params});
    },
    resetQueryParams() {
      setQueryParams(buildDefaultQueryParams());
    },
  };
}
