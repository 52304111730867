import React, { Component } from 'react';

import {AppContext} from '../contexts/AppContext';

import CheckoutNavBar from './CheckoutNavBar';

class CheckoutNavWrap extends Component {

   render() {
      return (
         <AppContext.Consumer>
            {context => (
                  <header className="checkout-nav-wrap">
                     {/* <HeaderBar /> */}
                     <CheckoutNavBar/>
                  </header>
            )}
         </AppContext.Consumer>
      );
   }
}

export default CheckoutNavWrap;
