import DAO from "./DAO";
import Endpoint from "../request/Endpoint";
import {
   DBCard,
} from "../interface/DBModels";

type ClientInfo = {
   clientSecret: string;
}

class StripeDAO extends DAO {
   private readonly getCardsEndpoint: Endpoint<{}, DBCard[]>;
   private readonly addCardEndpoint: Endpoint<{}, ClientInfo>;
   private readonly deleteCardEndpoint: Endpoint<{}, {}>;

   constructor() {
      super();

      this.getCardsEndpoint = Endpoint.for<{}, DBCard[]>('stripeCards');
      this.addCardEndpoint = Endpoint.for<{}, ClientInfo>('stripeCards');
      this.deleteCardEndpoint = Endpoint.for<{}, {}>('stripeCardsById');
   }

   async getAllCards(token: string = ""): Promise<DBCard[]> {
      console.log("getting cards from stripe");

      const response = await this.getCardsEndpoint.get(
         {token},
      );

      this.validateResponse(response);

      return response;
   }

   async addCard(/* data: CardInfo ,*/ token: string = "") {
      console.log("adding card to stripe");

      const urlParams = {
         token,
      }

      const response = await this.addCardEndpoint.post(
         {},
         urlParams,
      );

      this.validateResponse(response);
      return response;
   }

   async deleteCard(token: string = "", cardId: string) {
      const urlParams = {
         token,
         cardId,
      }

      const response = await this.deleteCardEndpoint.delete(urlParams);

      if (response) {
         this.validateResponse(response);
      }
   }
}

const instance = new StripeDAO();

Object.freeze(instance);

export default instance;