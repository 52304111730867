import React from 'react';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Checkbox, FormControlLabel
} from '@material-ui/core';

import {
  CardNumberElement, CardCvcElement, CardExpiryElement
} from '@stripe/react-stripe-js';

import CheckoutTextField from "../CheckoutTextField";
import AddressInput from '../address-page/AddressInput';
import MessageBanner from '../../MessageBanner';

type Props = {
  errorMessages: string[],
  sameBillingAddress: boolean,
  setSameBillingAddress: any,
  disabledCheckbox?: boolean
}

export default function PaymentInput(props: Props) {
  const checkboxDisabler =
    (props.disabledCheckbox === undefined)
    ?
      false
    :
      props.disabledCheckbox

   return (
    <div>
      <Typography variant="h6" noWrap gutterBottom={true}>
        New Credit Card
      </Typography>
      <MessageBanner 
        messageType={"error"}
        messageHeader={props.errorMessages[0]}
        messageContent={props.errorMessages[1]}/>
      <Grid container spacing={2}>
        <CheckoutTextField size={6}
          label="Card Number" placeholder=""
          inputComponent={CardNumberElement}/>
        <CheckoutTextField size={3}
          label="Expiration Date" placeholder=""
          inputComponent={CardExpiryElement}/>
        <CheckoutTextField size={3}
          label="CVC" placeholder="CVC"
          inputComponent={CardCvcElement}/>
        {
          (checkboxDisabler === true)
          ? 
            <Grid item>
            </Grid>
          :
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={props.sameBillingAddress}
                    onChange={() => props.setSameBillingAddress(!props.sameBillingAddress)}
                  />
                }
                label="Billing address is the same as shipping address"
              />
            </Grid>
        }
        
      </Grid>
      {
        (props.sameBillingAddress === false)
        ?
          <AddressInput
            title="Billing Address"
            isGuestAccount={false}
            accountEmail={""}
            currentlySelectedAddress={undefined}
          />
        : ""
      }
    </div>
   );
}