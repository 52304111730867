import React from 'react';

import AccountPageWrapper from '../../components/account/AccountPageWrapper';
import AddressEditor from '../../components/account/addresses/AddressEditor';

export default function AddressPage() {
 
  return (
    <AccountPageWrapper
      title={"Saved Addresses"}
      selected={2}
      inside={<AddressEditor />}/>
  );
}
