import React, { useState, useContext } from 'react';

import {
  useHistory,
} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import {
   Typography, TextField, FormControlLabel,
   Checkbox, Paper, CircularProgress, Button
} from '@material-ui/core';
import {AppContext} from '../../../contexts/AppContext';
import { DBAddress } from '../../../model/interface/DBModels';
import AddressDAO from "../../../model/dao/AddressDAO";
import CheckoutStepper from "../CheckoutStepper";
import MessageBanner from '../../MessageBanner';
import {labelToCode} from '../../_helpers/CountryType';
import {isValidEmailAddress} from '../../_helpers/EmailChecker';

import AddressInput from "./AddressInput"
import SavedAddresses from "./SavedAddresses"

type Props = {
  isGuestAccount: boolean,
  accountEmail: string,
  setAccountEmail: any,
  orderShippingAddress: DBAddress,
  setOrderShippingAddress: any,
  orderSavedShippingIndex: number,
  setOrderSavedShippingIndex: any
};

const AddressForm = function(props: Props) {
   const context = useContext(AppContext);
   let history = useHistory();

   const [loading, setLoading] = useState(true);
   const [errorMessage, setError] = useState(false);
   const [addressList, setAddressList] = useState<DBAddress[]>([]);

   const [selectedIndex, setSelectedIndex] = useState(props.orderSavedShippingIndex); // select a saved address
   const [addressFormVisible, setAddressFormVisible] = useState((props.orderSavedShippingIndex === -1)); // is the form visible
   const [toSaveAddress, setToSaveAddress] = useState(false); // should we save the new address

  React.useEffect(() => {
    let isActive = true;
    setLoading(true);
    setError(false);

    AddressDAO.getAddresses(context.token)
      .then((addressList) => {
          if (addressList) {
            if(addressList.length !== 0) {
              setAddressList(addressList);
              if(props.orderSavedShippingIndex === -1 && props.orderShippingAddress !== undefined) {
                let foundIndex = addressList.findIndex(
                  (a: DBAddress) => 
                    a.name === props.orderShippingAddress.name
                    && a.line1 === props.orderShippingAddress.line1
                    && a.line2 === props.orderShippingAddress.line2
                )
                if(foundIndex !== -1) {
                  setSelectedIndex(foundIndex);
                  setAddressFormVisible(false);
                }
                else {
                  setAddressFormVisible(true);
                }
              }
            }  
            if(addressList.length === 0) {
              setAddressFormVisible(true);
            }
            setLoading(false);
            setError(false);
          }
      }).catch(() => {
          if (isActive) {
             setError(true);
             setAddressFormVisible(true);
             setLoading(false);
          }
      });

    return () => {
      isActive = false;
    };
  }, [context.token, props.orderSavedShippingIndex, props.orderShippingAddress]);

  const toggleFormVisibility = (changeTo: boolean) => {
    if(changeTo !== addressFormVisible) {
      if(changeTo === true) { // input new address
        setSelectedIndex(-1);
        props.setOrderShippingAddress(undefined);
      }
      setAddressFormVisible(changeTo);
    }
  }

  const handleNewAddress = (formData: FormData, email: string) => {
    let countryCode = labelToCode(String(formData.get("country")));
    console.log(countryCode);
    let newAddress = {
      "id": "-1",
      "name": String(formData.get("name")),
      "phone": String(formData.get("phone")),
      "line1": String(formData.get("address-line-1")),
      "line2": String(formData.get("address-line-2")),
      "city": String(formData.get("city")),
      "state": String(formData.get("region")),
      "postalCode": String(formData.get("zip-code")),
      "country": countryCode,
      "notes": String(formData.get("notes")),
      "email": email
    }
    return newAddress;
  }

  const formHandleSubmit = (event: any) => {
    if(addressFormVisible === true) { // deal with new address
      const formData = new FormData(event.target as HTMLFormElement);
      if(props.isGuestAccount === true) { // guest account, needs email
        props.setAccountEmail(String(formData.get("email")));
        let newEmail = String(formData.get("email"));
        if(isValidEmailAddress(newEmail)) {
          let newAddress = handleNewAddress(formData, newEmail)
          props.setOrderSavedShippingIndex(-1);
          props.setOrderShippingAddress(newAddress);
          event.preventDefault();
          history.push("/checkout/payment");
        }
        else {
          event.preventDefault();
        }
      }
      else { // logged in, new address
        let accountEmail = props.accountEmail;
        let newAddress = handleNewAddress(formData, accountEmail)
        props.setOrderSavedShippingIndex(-1);
        props.setOrderShippingAddress(newAddress);
        
        if(toSaveAddress === true) {
          AddressDAO.addAddress(newAddress, context.token)
        }
        event.preventDefault();
        history.push("/checkout/payment");
      }
    }
    else { // logged in, saved address
      props.setOrderSavedShippingIndex(selectedIndex);
      props.setOrderShippingAddress(addressList[selectedIndex]);
      event.preventDefault();
      history.push("/checkout/payment");
    }
    
  }

  const saveAddressCheckbox =
    (selectedIndex === -1 && addressFormVisible === true && props.isGuestAccount === false)
    ?
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            onChange={() => setToSaveAddress(!toSaveAddress)}
          />
        }
        label="Save address"
      />
    :
      ""

  // if addressFormVisible == false && selectedIndex == -1
  //  true
  let nextIsDisabled =
    (addressFormVisible === false)
    ?
        (selectedIndex !== -1)
        ? false
        : true
    :
        false

   return (
     <div>
       <form id="address-form" onSubmit={formHandleSubmit}> {/* outer wrapper */}
          <Grid item xs={12}> {/* title */}
            <CheckoutStepper step={0}/>
          </Grid>
          <Paper elevation={3}> {/* info */}
            {
             loading
             ?
             <div className="loading-screen">
               <Typography variant="subtitle1" noWrap align="center">
                 <CircularProgress size={'1em'}/>
                 <br/>
                 Loading saved addresses...
               </Typography>
             </div>
             :
              <Grid item xs={12} className="checkout-form-inner">
                <SavedAddresses
                  addressList={addressList}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  addressFormVisible={addressFormVisible}
                  toggleFormVisibility={toggleFormVisibility}
                />
                {
                  (addressFormVisible === true)
                  ?
                  <AddressInput
                    title={"New Shipping Address"}
                    isGuestAccount={props.isGuestAccount}
                    accountEmail={props.accountEmail}
                    currentlySelectedAddress={props.orderShippingAddress}
                    aria-hidden={!addressFormVisible}
                  />
                  : ""
                }
                <br/> {/* delivery instructions */ }
                <TextField
                  id="outline-multiline"
                  fullWidth
                  multiline
                  rows={4}
                  name="notes"
                  label="Add delivery instructions (optional)"
                  placeholder="Security codes, call box, navigation instructions"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  margin='dense'
                />
                {saveAddressCheckbox} {/* save address */ }
              </Grid>
            }
          </Paper>
       </form>
       <Grid item container xs={12} justify="flex-end">
         <Button
          variant="contained" color="primary" type="submit" form="address-form"
          disabled={loading || nextIsDisabled} className="checkout-button" disableElevation
         >
           Next
         </Button>
       </Grid>
     </div>
   );
}

export default AddressForm;
