import React from 'react';

import OrderList from "../../components/account/orderhistory/OrderList"
import AccountPageWrapper from '../../components/account/AccountPageWrapper';

export default function OrderHistoryPage() {
 
  return (
    <AccountPageWrapper
      title={"Order History"}
      selected={0}
      inside={<OrderList />}/>
  );
}
