import React, { useState, useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Paper, CircularProgress, Button
} from '@material-ui/core';

import {AppContext} from '../../../contexts/AppContext';
import AddressDAO from "../../../model/dao/AddressDAO";
import UserDAO from "../../../model/dao/UserDAO";
import { DBAddress } from '../../../model/interface/DBModels';
import {labelToCode} from '../../_helpers/CountryType';

import AddressInput from "../../checkout/address-page/AddressInput"
import SavedAddresses from "../../checkout/address-page/SavedAddresses"
import AddressDeletionButton from './AddressDeletionButton';

export default function AddressEditor() {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [addressList, setAddressList] = useState<DBAddress[]>([]);
  const [addressListChanged, setAddressListChanged] = useState(false);

  const [editingAddress, setEditingAddress] = useState<DBAddress>();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [addressFormVisible, setAddressFormVisible] = useState(true);

  const [accountEmail, setAccountEmail] = useState<string>();
  const [accountInfoChanged, setAccountInfoChanged] = useState(false);

  React.useEffect(() => {
    let isActive = true;
    setLoading(true);
    setError(false);

    
    AddressDAO.getAddresses(context.token)
      .then((addressList) => {
          if (addressList) {
            setAddressList(addressList);
            if(selectedIndex !== -1) {
              setSelectedIndex(-1);
            }
            setLoading(false);
            setError(false);
          }
      }).catch(() => {
          if (isActive) {
             setError(true);
             setLoading(false);
          }
      });

    return () => {
      isActive = false;
    };
  }, [addressListChanged]);

  React.useEffect(() => {
    let isActive = true;
    setLoading(true);
    setError(false);

    UserDAO.getAccountInfo(context.token)
      .then((accountInfo) => {
            setAccountEmail(accountInfo.email);
            setLoading(false);
            setError(false);
        }).catch(() => {
          if (isActive) {
              setError(true);
              setLoading(false);
          }
      });

    return () => {
      isActive = false;
    };
  }, [accountInfoChanged]);

  React.useEffect(() => {
    setEditingAddress(addressList[selectedIndex])
  }, [selectedIndex])

  const toggleFormVisibility = (changeTo: boolean) => {
    if(changeTo !== addressFormVisible) {
      if(changeTo === true) { // input new address
        setSelectedIndex(-1);
      }
      setAddressFormVisible(changeTo);
    }
  }

  const reloadAddressList = () => {
    setAddressListChanged(!addressListChanged);
  }

  const formHandleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.target as HTMLFormElement);

    let countryCode = labelToCode(String(formData.get("country")));
    let addressFromForm = {
      "id": "-1",
      "name": String(formData.get("name")),
      "phone": String(formData.get("phone")),
      "line1": String(formData.get("address-line-1")),
      "line2": String(formData.get("address-line-2")),
      "city": String(formData.get("city")),
      "state": String(formData.get("region")),
      "postalCode": String(formData.get("zip-code")),
      "country": countryCode,
      "notes": String(formData.get("notes")),
      "email": accountEmail
    }
    if(selectedIndex === -1) { // new address
      await AddressDAO.addAddress(addressFromForm, context.token)
    }
    else { // edit existing
      if(editingAddress !== undefined) {
        await AddressDAO.updateAddress(addressFromForm, context.token, editingAddress.id);
      }
    }
    reloadAddressList();
  }

   return (
    <Grid item xs> {/* outer wrapper */}
      <Paper elevation={3} className="order-history-paper"> {/* items in cart */}
        {
          loading
          ?
          <div className="loading-screen">
            <Typography variant="subtitle1" noWrap align="center">
              <CircularProgress size={'1em'}/>
              <br/>
              Loading addresses...
            </Typography>
          </div>
          :
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <SavedAddresses
                  addressList={addressList}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  addressFormVisible={addressFormVisible}
                  toggleFormVisibility={toggleFormVisibility}
                />
                <form id="address-form" onSubmit={formHandleSubmit}>
                  <AddressInput
                    title={"Shipping Address"}
                    isGuestAccount={false}
                    accountEmail={String(accountEmail)}
                    currentlySelectedAddress={editingAddress}
                  />
                </form>
              </Grid>
              <Grid container xs={12} spacing={2} justify="flex-end">
                <Grid item>
                  {
                    (selectedIndex !== -1)
                    ?
                      <AddressDeletionButton 
                        addressId={editingAddress?.id}
                        reloadFunction={reloadAddressList}/>
                    :
                      ""
                  }
                </Grid>
                <Grid item>
                  <Button
                  variant="contained" color="primary" type="submit" form="address-form"
                  disabled={loading} disableElevation>
                    save address
                  </Button>
                </Grid>
              </Grid>
            </Grid>
        }
      </Paper>


    </Grid>
   );
}

