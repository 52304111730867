import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {createBrowserHistory} from "history";
import { SnackbarProvider } from "notistack";
import { createMuiTheme, Grow, ThemeProvider } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";

import {AppProvider} from "./contexts/AppContext";

import ReactGA from "react-ga";

import RouterLayer from "./navigation/RouterLayer";

// For Google Analytics
const history = createBrowserHistory();

history.listen((location) => {
   ReactGA.set({page: location.pathname + location.search});
   ReactGA.pageview(location.pathname + location.search);
   window.scrollTo({top: 0});
});

const GrowTransition = (props: TransitionProps) => {
   return <Grow {...props} />;
 }

const theme = createMuiTheme({
   palette: {
      primary: {
         main: "#156cf0",
      },
      secondary: {
         main: "#08335E",
      },
   },
})

const queryClient = new QueryClient();

export default function App() {

   React.useEffect(() => {
      ReactGA.pageview(window.location.pathname);
   }, []);

   return (
      <QueryClientProvider client={queryClient}>
         <AppProvider>
            <ThemeProvider theme={theme}>
               <SnackbarProvider maxSnack={3} TransitionComponent={GrowTransition} anchorOrigin={{vertical: "bottom", horizontal: "left"}}>
                  <RouterLayer/>
               </SnackbarProvider>
            </ThemeProvider>
         </AppProvider>
      </QueryClientProvider>
   );
}
