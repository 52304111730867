import React from 'react';

import {
  NavLink as RouterLink,
} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Link
} from '@material-ui/core';

type Props = {
   title: string,
   shippingCity: string,
   shippingCountry: string,
   shippingLine1: string,
   shippingLine2: string,
   shippingPostalCode: string,
   shippingState: string,
   shippingName: string,
   shippingPhone: string,
   editable: boolean
};

export default function DisplayAddress(props: Props) {
   return (
     <Grid item xs className="order-grid-item">
       <Typography variant="h6" display="inline" noWrap gutterBottom={true}>
          {props.title}
        </Typography>
        {
         (props.editable === true)
         ?
            (props.title === "Billing Address")
            ?
            <Link component={RouterLink} to={`/checkout/payment`} variant="body2" display="inline" className="inline-edit-button">
            (Edit)
            </Link>
            :
            <Link component={RouterLink} to={`/checkout/`} variant="body2" display="inline" className="inline-edit-button">
            (Edit)
            </Link>
         :
            ""
       }
       <Grid item>
       <Typography variant="body1">
            {props.shippingName}
         </Typography>
         <Typography variant="body2">
            {props.shippingLine1} {props.shippingLine2} <br/>
         </Typography>
         <Typography variant="body2">
            {props.shippingCity}, {props.shippingState} {props.shippingPostalCode}
         </Typography>
       </Grid>
     </Grid>
   );
}
