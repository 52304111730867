import React, {useContext} from "react"

import { Box, Grid, Typography } from "@material-ui/core"

import SearchContainer from "../../search2/SearchContainer";
import ResultDAO from "../../../model/dao/ResultDAO";
import SearchPageTwo from "../../search2/SearchPage2";

type TabPanelProps = {
   children?: React.ReactNode;
   inputText: string;
   index: any;
   value: any;
}


export default function SearchTab(props: TabPanelProps) {
   const { inputText, children, value, index, ...other } = props;

   // React.useEffect(() => {
   //    let isActive = true;

   //    console.log("setting search text B: " + inputText);
   //    context.setSearchText(inputText);
   //    context.searchListener.clearQueryParams();
   //    context.searchListener.updateText(inputText);

   //    return () => {
   //       isActive = false;
   //    };
   // }, [inputText]);
   
   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`vertical-tabpanel-${index}`}
         aria-labelledby={`vertical-tab-${index}`}
         {...other}
      >
         {value === index &&
         // <Box p={3}>
         //    <Grid container spacing={3}>
         //       <Grid item xs={12}>
         //          <div className="page-title">
         //             <Typography variant="h4" noWrap>
         //                {'Search Results for "' + inputText + '"'}
         //             </Typography>
         //          </div>
         //       </Grid>
         //    </Grid>
         //    <SearchContainer daoFn={ResultDAO.searchItems} />
         // </Box>
         <div className="u-padding-1">
            <SearchPageTwo/>
         </div>
         }
      </div>
   );
}