import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';

import {
   Grid,
   Typography,
   Divider,
   Hidden, 
   CircularProgress,
   Paper,
} from '@material-ui/core';
import {
   Alert,
   Pagination,
} from '@material-ui/lab';

import {
   PerPageSelector,
   SortSelector,
} from './components/Selectors';
import FilterDisplay from './components/FilterDisplay';
import AbstractItemDisplay from './components/AbstractItemDisplay';
import { useGlobalContexts, useSearchResult } from '../../hooks';
import {wrapWithIndexSignature} from "../../components/_helpers/UrlHelper";
import { QueryParamsModel } from '../../model/interface/Models';
import { DBAbstractItem, DBSearchResults } from '../../model/interface/DBModels';

type QueryParams = { [param: string]: string[] };

type SearchContainerProps = {
   daoFn: (qps: QueryParams) => Promise<DBSearchResults<DBAbstractItem>>,
}

export default function SearchContainer(props: SearchContainerProps) {
   const {queryParams, updateQueryParams} = useGlobalContexts();

   let history = useHistory();

   const {
      doSearch, isLoading, isError,
      results, resultCount, filters,
      page, maxPages, perPage,
   } = useSearchResult<DBAbstractItem>(wrapWithIndexSignature(props.daoFn));

   useEffect(() => {
      console.log("search container use Effect");
      doSearch();
      // history.replace({
      //    search: getQueryParamString({...queryParams, filters: null, ...queryParams.filters }),
      // });
   }, []);

   const buildResultsString = () => {
      const startNum = ((page - 1) * perPage) + 1;
      
      return (
         "Showing " + 
         startNum + "-" + (startNum + results.length - 1) +
         " of " +
         resultCount + " Items"
      );
   }

   return (
      <Grid container spacing={3}>
         <Grid item xs={12}>
            {isLoading || (results && !results.length) ?
               <div className="u-padding-2"></div>
            :
               <div className="u-flexItem">
                  <Typography variant="h6" noWrap>
                     {results && buildResultsString()}
                  </Typography>
               </div>
            }
         </Grid>
            <Grid item md={3} xs={12}>
               <FilterDisplay filters={filters || []} doSearch={doSearch} currentlySelected={queryParams.filters} updateQueryParams={updateQueryParams}/>
            </Grid>
            <Grid item lg={7} md={9} xs={12}>
               <Grid item xs={12}>
                  {isLoading ? (
                     <Paper className="u-flexItem" elevation={3}>
                        <div className="u-padding-4 u-centerVerticalRelative">
                           <CircularProgress size={"4em"}/>
                        </div>
                     </Paper>
                  ) : isError ? (
                     <Alert severity="error">An error occurred while searching</Alert>
                  ) : !results.length ? (
                     <Alert severity="info">Sorry, we {"couldn't"} find any matching results.</Alert>
                  ) : (
                     <div>
                        <div className="u-flexItem">
                           <PerPageSelector/>
                           <SortSelector/>
                        </div>
                        <div>
                           {results.map((result) => (
                              <AbstractItemDisplay key={result.id} item={result} />
                           ))}
                        </div>
                     </div>
                  )}
               </Grid>
            </Grid>
            <Hidden mdDown>
               <Grid item xs={1}>
                  <Divider orientation="vertical" />
               </Grid>
            </Hidden>
            <Hidden mdDown>
               <Grid item xs={1}>
               </Grid>
            </Hidden>
            <Grid item xs={12} zeroMinWidth>
               <div className="pagination">
                  <Pagination
                     shape="rounded"
                     count={maxPages}
                     page={page}
                     onChange={(event, page) => updateQueryParams({page: page})}
                  />
               </div>
            </Grid>
         </Grid>
   );

}
