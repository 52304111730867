import React from 'react';

import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';

import AccountPageWrapper from '../../components/account/AccountPageWrapper';
import PaymentEditor from '../../components/account/payments/PaymentEditor';

export default function PaymentPage() {
  const stripePromise = loadStripe('pk_live_51Io7vqLyYsmd6Jy6FyE2nwchwLGEBe2iHpnrT4dOeuhAoBX2LvtLAvcveDgHnQVnDE73z2kYnWPMIBoI2BjWvF9m00QN9e0s0h');

  return (
    <Elements stripe={stripePromise}>
      <AccountPageWrapper
      title={"Payment Information"}
      selected={3}
      inside={<PaymentEditor />}/>
    </Elements>
  );
}
