import React from 'react';

import { Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

type Props = {
  messageType: "success" | "info" | "warning" | "error" | undefined,
  messageHeader: string,
  messageContent?: string,
}

export default function MessageBanner(props: Props) {
  if(props.messageHeader === undefined || props.messageHeader === "") {
    return(<div/>)
  }
  return(
    <Grid item xs={12} className="message-banner">
      <Alert severity={props.messageType} variant="filled">
        <AlertTitle>{props.messageHeader}</AlertTitle>
        {props.messageContent}
      </Alert>
    </Grid>
  );
}