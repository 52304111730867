import React, {MouseEvent, useContext, ChangeEvent, useState} from "react";
import { useSnackbar } from 'notistack';
import Button, {ButtonProps} from "@material-ui/core/Button";
import {
   DialogContent, DialogActions, Dialog,
   Typography, TextField, DialogTitle,
} from "@material-ui/core";

import {AppContext} from "../../contexts/AppContext";
import AssemblyDAO from "../../model/dao/AssemblyDAO";
import { Credentials, DBAssembly, DBItem } from "../../model/interface/DBModels";
import DropDown from "../search/components/DropDown";
import AccountPromptButton from "../search/components/AccountPromptButton";

type Props = ButtonProps & {
   items: DBItem[],
}

export default function SaveItemDialogButton(props:Props) {
   const {items, ...rest} = props;

   const context = useContext(AppContext);
   const {enqueueSnackbar} = useSnackbar();

   const [open, setOpen] = useState(false);
   const [tempCreds, setTempCreds] = useState<Credentials>({token: "", account: {id:"",email:"",firstName:"",lastName:"",guest:false}});
   const [projects, setProjects] = useState<DBAssembly[]>([]);
   const [selectedProjectId, setSelectedProjectId] = useState("");
   const [defaultName, setDefaultName] = useState("");
   const [name, setName] = useState('');

   React.useEffect(() => {
      let isActive = true;
      
      if (tempCreds.token) {
         AssemblyDAO.getNamesOnly(tempCreds.token)
           .then((projects) => {
               if (isActive) {
                  setProjects(projects);
               }
           }).catch(() => {
           });
   
         }
      return () => {
         isActive = false;
      };
    }, [tempCreds]);

   const loadProjectList = () => {
      console.log("SaveItemDialogButton loadProjectList")
      AssemblyDAO.getNamesOnly(tempCreds.token || context.token).then(
         (projects) => {
            setProjects(projects);
         }
      );
   }

   const handleCreateClick = (event: MouseEvent) => {
      event.preventDefault();

      AssemblyDAO.createAssembly({name}, tempCreds.token || context.token)
         .then((res) => {
            enqueueSnackbar("Project '" + name + "' created.", {variant: "success"});
            setDefaultName(res.name);
            setSelectedProjectId(res.id);
            loadProjectList();
         })
         .catch(() => {
            enqueueSnackbar("Unable to create project.", {variant: "error"});
         });
   };

   const handleSaveClick = (event: MouseEvent) => {
      event.preventDefault();
      console.log("handleSaveClick: items: ", items);
      let itemIds: {id: string}[] = [];
      for(let i = 0; i < items.length; i++) {
         console.log(items[i].id)
         itemIds.push({id: items[i].id})
       }
       console.log("handleSaveClick: itemIds: ", itemIds);
      AssemblyDAO.addItems(tempCreds.token || context.token, selectedProjectId, itemIds)
         .then(() => {
         enqueueSnackbar("Item saved.", {variant: "success"});
         console.log("handleSaveClick: items added to " + selectedProjectId)
         })
         .catch(() => {
         console.log("handleSaveClick: error adding items to " + selectedProjectId)
         enqueueSnackbar("Unable to save item.", {variant: "error"});
         });

      handleClose();
   };

   const altCb = (creds:Credentials) => {
      setTempCreds(creds);
      setOpen(true);
   }

   const handleClickOpen = () => {
      setOpen(true);
      loadProjectList();
   };

   const handleClose = () => {
      setOpen(false);
      if (tempCreds.token) {
         context.setCredentials(tempCreds);
      }
   };

   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
   }

   const handleSelection = (projectName: string) => {
      setSelectedProjectId(projects.find(p => p.name === projectName)?.id || "");
      setDefaultName(projectName);
   }

   const label =
      (items.length === 1)
      ? "Save To Project"
      : "Save Items To Project";

   return (
      <div>
         <AccountPromptButton
            buttonLabel={label}
            actionHandler={handleClickOpen}
            altCb={altCb}
            fullWidth
            disableGuest={true}
            {...rest}
         />

         <Dialog
            key={"save-dialog"}
            open={open}
            maxWidth="lg"
            onBackdropClick={handleClose}
            onEscapeKeyDown={handleClose}
            keepMounted
         >
            <DialogTitle>
               <Typography color="primary">
                  {label}
               </Typography>
            </DialogTitle>
            <DialogContent dividers>
               
               <div className="u-paddingVertical-1" >
                  Create a New Project
               </div>
               
               <div className="u-flexItem u-centerVerticalRelative">
                  <TextField onChange={handleChange} label="Project Name" variant="outlined"/>
                  <div className="u-marginHorizontal-1">
                     <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateClick}
                     >
                        Create
                     </Button>
                  </div>
               </div>
               
               <div className="u-paddingVertical-1" >
                  Select an Existing Project
               </div>

               <DropDown defaultValue={defaultName} inputArray={projects.map(project => project.name)} selected={handleSelection}/>

            </DialogContent>

            <DialogActions>
               <Button
                  variant="outlined"
                  onClick={handleClose}
               >
                  Cancel
               </Button>

               <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveClick}
                  disabled={!(selectedProjectId)}
               >
                  Save
               </Button>
            </DialogActions>
         </Dialog>
      </div>
   );
}
