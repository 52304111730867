import React, { useState, useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import {
   Typography,
   TableContainer, Table, TableBody,
   Paper, CircularProgress, TableCell, TableHead, TableRow, Hidden
} from '@material-ui/core';

import {AppContext} from '../../../contexts/AppContext';
import ResultDAO from "../../../model/dao/ResultDAO";
import { DBOrder } from '../../../model/interface/DBModels';
import OrderRow from "./OrderRow"

export default function OrderList() {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [pendingOrders, setPendingOrders] = useState<DBOrder[]>([]);
    const [otherOrders, setOtherOrders] = useState<DBOrder[]>([]);

    React.useEffect(() => {
    let isActive = true;

    setLoading(true);
    setError(false);

    ResultDAO.getAllOrders({["perPage"]: ["10000"]}, context.token)
      .then((orderList) => {
          if (isActive) {
            orderList.results.sort(function(a, b) {
              return (a.created < b.created) ? 1 : ((a.created > b.created) ? -1 : 0);
            });
            setPendingOrders(orderList.results.filter(order => order.status === "Pending"));
            setOtherOrders(orderList.results.filter(order => order.status !== "Pending"));
            setLoading(false);
            setError(false);
          }
      }).catch(() => {
          if (isActive) {
              setError(true);
              setLoading(false);
          }
      });

      return () => {
        isActive = false;
      };
    }, [context.token]);

   return (
    <Grid item xs> {/* outer wrapper */}
      <Paper elevation={3} className="order-history-paper"> {/* items in cart */}
        {/* <Typography variant="h5">
          Order History
        </Typography> */}
        {
          loading
          ?
          <div className="loading-screen">
            <Typography variant="subtitle1" noWrap align="center">
              <CircularProgress size={'1em'}/>
              <br/>
              Loading orders...
            </Typography>
          </div>
          :
            (pendingOrders.length === 0 && otherOrders.length === 0)
            ?
              <div className="loading-screen">
                <Typography variant="subtitle1" noWrap align="center">
                  You have no previous orders!
                </Typography>
              </div>
            :
              <div>
                <OrderTable
                  title={"Pending Orders"}
                  orderList={pendingOrders}/>
                <OrderTable
                  title={"Past Orders"}
                  orderList={otherOrders}/>
              </div>
        }
      </Paper>
    </Grid>
   );
}

type TableProps = {
  title: string,
  orderList: DBOrder[],
};

const OrderTable = function(props: TableProps) {
  return(
    <div>
      <Typography variant="h6" className="order-history-title">
        {props.title}
      </Typography>
      <TableContainer className="order-history-table">
        <Table>
          <TableHead>
            <Hidden mdDown>{/* large version */}
              <TableRow>
                <TableCell>Order #</TableCell>
                <TableCell align="left">Placed</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left" aria-label="Order Link"></TableCell>
              </TableRow>
            </Hidden>
          </TableHead>
          <TableBody>
              {props.orderList.map((order: DBOrder, index) => {
                return (
                  <OrderRow order={order}/>
                )}
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
