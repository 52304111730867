import React, { useState, useContext } from 'react';

import {
  useHistory,
} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import {
   Button, Hidden, Typography
} from '@material-ui/core';
import {AppContext} from '../../contexts/AppContext';
import ResultDAO from "../../model/dao/ResultDAO";

import CartItems from "../../components/cart/CartItems"
import OrderSummary from "../../components/OrderSummary"

export default function CartPage() {
  const context = useContext(AppContext);
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);

  const [cartChanged, setCartChanged] = useState(false);
  const [priceChanged, setPriceChanged] = useState(false);

  React.useEffect(() => {
    let isActive = true;

    setLoading(true);
    setError(false);

    ResultDAO.getCart({}, context.token)
      .then((cartItems) => {
          if (isActive) {
             setCartItemCount(cartItems.results.length);
             setLoading(false);
             setError(false);
          }
      }).catch(() => {
          if (isActive) {
             setError(true);
             setLoading(false);
          }
      });

    return () => {
      isActive = false;
    };
  }, [cartChanged, context.token]);

  const validToken = 
    (context.token === undefined)
    ? false
    : 
      (context.token.length === 0)
      ? false
      : true;

  const setCheckoutDisabled =
    (validToken || loading)
    ? 
      (cartItemCount !== 0)
      ? false
      : true
    : true

  return (
    <div className="page-offset cart-page">
      <Grid container item justify="center" xs={12}> {/* main page */}
        <Grid item xs={12}> {/* title */}
          <Typography variant="h5" noWrap>
             Shopping Cart
          </Typography>
        </Grid>

        <Grid container spacing={4}>        
          <Hidden mdUp> 
            <Grid item xs={12} md={3}> {/* summary + purchase button */}
              <br/>
              <Button
                variant="contained" color="primary" onClick={() => {history.push("/checkout")}} style={{backgroundColor:"orange"}}
                className="checkout-side-button" fullWidth={true} disableElevation disabled={setCheckoutDisabled}
              >
                Purchase Order
              </Button>
              <OrderSummary
                cartChanged={cartChanged}
                priceChanged={priceChanged}
                orderConfirmed={false}/>
              <br/>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={9}>
            <CartItems
            cartChanged={cartChanged}
            setCartChanged={setCartChanged}
            priceChanged={priceChanged}
            setPriceChanged={setPriceChanged}/> {/* left side of cart */}
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={3}> {/* summary + purchase button */}
              <Button
                variant="contained" color="primary" onClick={() => {history.push("/checkout")}} style={{backgroundColor:"orange"}}
                className="checkout-side-button" fullWidth={true} disableElevation disabled={setCheckoutDisabled}
              >
                Purchase Order
              </Button>
              <OrderSummary
                cartChanged={cartChanged}
                priceChanged={priceChanged}
                orderConfirmed={false}/>
            </Grid>
          </Hidden>
       </Grid>
      </Grid>
    </div>
  );
}
