import React, {useContext, useState} from "react";
import {NavLink} from "react-router-dom";
import { useSnackbar } from 'notistack';

import {
   Button,
   Grid,
   Paper, PaperProps, Typography,
} from "@material-ui/core";

import {AppContext} from '../../../contexts/AppContext';
import {DBItem} from "../../../model/interface/DBModels";
import CartDAO from "../../../model/dao/CartDAO";

type Props = PaperProps & {
   item: DBItem,
   handleRemoveAction: () => void,
   padding?: 0 | 1 | 2 | 3 | 4,
}

export default function ItemDisplay(props: Props) {
   const {
      item,
      handleRemoveAction,
      padding = 2,
      ...rest
   } = props;

   const context = useContext(AppContext);
   const {enqueueSnackbar} = useSnackbar();

   const handleAddAction = () => {
      console.log("adding to cart: " + item.id);
      CartDAO.addItems(context.token, [{
         quantity: 1,
         itemId: item.id,
      }]).then(() => {
         enqueueSnackbar("Item added to cart.", {variant: "success"})
         context.toggleCartUpdate();
      }).catch(() => (
         enqueueSnackbar("Could not add item.", {variant: "error"})
      ));
   }

   return (
      <NavLink className="remove-link" exact to={"/item/" + item.exactItemGroupingId}>
         <Paper
            elevation={2}
            className={`u-padding-${padding}`}
            {...rest}
         >
            <Grid container>
               <Grid item lg={2} xs={3}>
                  <div className="u-flexItem u-heightFull u-centerVerticalRelative" >
                     {item.image ? 
                        <img src={item.image} width="130"/>
                     :
                        <div></div>
                     }
                  </div>
               </Grid>
               <Grid item lg={7} xs={6}>
               <Grid spacing={2} container >
                     <Grid item xs={12}>
                        <div className="paper-title">
                           {item.name}
                        </div>
                     </Grid>
                     <Grid item xs={12}>
                        <div className="u-flexItem">
                           <div className="u-paddingRight-1">
                              <Button variant="contained" size="small" color="primary"  onClick={handleAddAction} style={{backgroundColor:"orange"}}>
                                 Add to Cart
                              </Button>
                           </div>
                           <div className="txt-btn">
                              <Button variant="outlined" size="small" color="inherit" onClick={handleRemoveAction}>
                                 Remove Item
                              </Button>
                           </div>
                        </div>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item xs={3}>
                  <Grid spacing={1} container>
                     <Grid item xs={12}>
                        <div className="u-paddingLeft-1 u-flexItem">
                           <div className="u-paddingTop-1 bright">
                              <Typography variant="h6">
                                 {"$" + item.price.toFixed(2) + " / " + item.unit}
                              </Typography>
                           </div>
                           <div className="u-paddingLeft-3 u-flexItem u-centerVerticalRelative">
                              <img src={item.sellerLogo} height="46"/>
                           </div>
                        </div>
                     </Grid>
                     <Grid item xs={12}>
                        <button className="u-paddingLeft-1 underlined-clickable">
                           See all prices...
                        </button>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </Paper>
      </NavLink>
   );
}