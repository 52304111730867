import React from 'react';

import { Typography } from '@material-ui/core';

import DropDown from '../../../components/input/DropDown';
import { useGlobalContexts } from '../../../hooks';

export const PerPageSelector = () => {
   const {queryParams, updateQueryParams} = useGlobalContexts();

   return (
      <div className="u-paddingHorizontal-1 u-flexItem u-centerVerticalRelative">
         <Typography className="u-paddingHorizontal-1">
            Per Page: 
         </Typography>
         <DropDown
            inputArray={["20", "50", "100"]}
            selected={(val: string) => updateQueryParams({perPage: parseInt(val) || 20})}
            defaultValue={queryParams.perPage.toString()}
         />
      </div>
   );
}

export const SortSelector = () => {
   const {queryParams, updateQueryParams} = useGlobalContexts();

   const helper = (s: string) => {
      switch (s) {
         case "relevance":
            return "Relevance";
         case "Relevance":
            return "relevance";
         case "matchCount":
            return "Most Matches";
         case "Most Matches":
            return "matchCount";
         default:
            return "relevance"
      }
   }

   return (
      <div className="u-paddingHorizontal-1 u-flexItem u-centerVerticalRelative">
         <Typography className="u-paddingHorizontal-1">
            Search By: 
         </Typography>
         <div className="search-by">
            <DropDown
               inputArray={["Relevance", "Most Matches",]}
               selected={(val: string) => updateQueryParams({sort: helper(val)})}
               defaultValue={helper(queryParams.sort)}
            />
         </div>
      </div>
   );
}