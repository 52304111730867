import React, { useState, useContext, useEffect } from "react";
import {
   useHistory,
   NavLink as RouterLink
} from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Button, TextField, Grid, Hidden, Badge, Box, Typography } from "@material-ui/core";
import {
   createMuiTheme,
   ThemeProvider,
} from "@material-ui/core/styles";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import logo from "../assets/AS-title-transparent-cropped.png";

import { AppContext } from "../contexts/AppContext";
import { getQueryParamString } from "../components/_helpers/UrlHelper";
import SearchListenerCollection from "../pages/search/SearchListenerCollection";
import CategoryButton from "./components/CategoryButton";
import LoginDialog from "./components/LoginDialog";
import AccountMenu from "./components/AccountMenu";
import ResultDAO from "../model/dao/ResultDAO";
import { useGlobalContexts } from "../hooks";

//old colors: primary: "#2F5482" secondary: "#AD7B3E"

const customTheme = createMuiTheme({
   palette: {
      primary: {
         main: "#FFF"
      },
      secondary: {
         main: "#156cf0",
      }
   },
});

type Props = {
   search: SearchListenerCollection,
};

type Event = {
   target: {
      value: string
   }
};

export default function NavBar(props: Props) {
   const { queryParams, updateQueryParams } = useGlobalContexts();
   const [searchText, setSearchText] = useState("");
   const [loginOpen, setLoginOpen] = useState(false);
   const [signUpOpen, setSignUpOpen] = useState(false);
   const [cartCount, setCartCount] = useState(0);
   const [cartUpdated, setCartUpdated] = useState(false);
   const [showSuggestions, setShowSuggestions] = useState(false)
   const [recentSearch, setrecentSearch] = useState<Array<string>>([])

   let history = useHistory();
   const context = useContext(AppContext);

   let accountInfo: any = context.account
   // // console.log(accountInfo.guest)
   // console.log("token: " + context.token + " | isGuest: " + accountInfo.guest)

   useEffect(() => {
      context.toggleCartUpdate = toggleCartUpdate;
   }, []);

   useEffect(() => {
      let isActive = true;

      ResultDAO.getCart({}, context.token)
         .then((res) => {
            if (isActive) {
               setCartCount(res.resultCount);
            }
         }).catch(() => {
            setCartCount(0);
         });
      return () => {
         isActive = false;
      };
   }, [context.token, cartUpdated]);

   useEffect(() => {
      setSearchText(context.searchText)
   }, [context.searchText]);


   /**
    * Used to tell the cart to update its count
    */
   const toggleCartUpdate = () => {
      setCartUpdated(!cartUpdated);
   }

   const handleClickLogin = () => {
      setLoginOpen(true);
   };

   const handleClickSignUp = () => {
      setSignUpOpen(true);
   };

   const handleClose = () => {
      setLoginOpen(false);
      setSignUpOpen(false);
   };

   const loginButton = (context.token && accountInfo.guest === false) ?
      <div className="nav-btn link-item">
         <AccountMenu />
      </div>
      :
      <div className="nav-btn-txt link-item">
         <Button variant="outlined" color="inherit" onClick={handleClickLogin}>
            Login
         </Button>
      </div>
      ;

   const signUpButton = (context.token && accountInfo.guest === false) ?
      <div></div>
      :
      <div className="nav-btn link-item sign-up-btn">
         <Button variant="outlined" color="inherit" onClick={handleClickSignUp}>
            Sign Up
         </Button>
      </div>
      ;

   const handleChange = (evt: Event) => {
      const searchText = evt.target.value;

      setSearchText(searchText);
   };

   const doSearch = () => {
      console.log("setting search text: " + searchText);
      if (searchText) {
         context.setSearchText(searchText);
         saveRecentSearch(searchText)
         context.searchListener.clearQueryParams();
         context.searchListener.updateText(searchText);
         history.push({
            pathname: "/search",
            search: getQueryParamString(context.searchListener.getQueryParams()),
         });
      }
   };

   const checkForSubmit = (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
         doSearch();
      }
   };

   const handleClick = () => {
      history.push("/");
   }

   const selectRecents = (text: string) => {
      setSearchText(text)
      setShowSuggestions(false)
   }

   const saveRecentSearch = (text: string) => {
      if (text == null || text == '')
         return
      if (recentSearch.indexOf(text) != -1)
         return
      const jsonParsed = JSON.stringify([text].concat(recentSearch))
      localStorage.setItem("AS_MOST_RECENT_SEARCHES", jsonParsed);
   }

   const recentSearches1 = recentSearch.filter(s => s.toUpperCase().includes(searchText.toUpperCase()))

   React.useEffect(() => {
      const recenthistory = localStorage.getItem("AS_MOST_RECENT_SEARCHES")
      if (recenthistory != null && recenthistory != '')
         setrecentSearch(JSON.parse(recenthistory))
   }, [localStorage.getItem("AS_MOST_RECENT_SEARCHES")])

   const searchEnsemble = history.location.pathname !== "/" ?
      <div className="nav-center">
         <div className="search-area">
            <Hidden xsDown>
               <CategoryButton childClassName="nav-category-button" />
            </Hidden>
            <div className="nav-search-bar">
               {console.log("search text: " + context.searchText)}
               {history.location.pathname.includes("search") && context.searchText ?
                  <TextField
                     aria-label="search"
                     variant="outlined"
                     onKeyUp={checkForSubmit}
                     onChange={handleChange}
                     onFocus={() => setShowSuggestions(true)}
                     value={searchText}
                     className="input-input"
                     color="secondary"
                     inputProps={{ "className": "search-bar-input", "aria-label": "search" }}
                     InputProps={{
                        className: "search-bar-input",
                     }}
                  />
                  :
                  <TextField
                     aria-label="search"
                     variant="outlined"
                     onKeyDown={checkForSubmit}
                     onChange={handleChange}
                     value={searchText}
                     onFocus={() => setShowSuggestions(true)}
                     placeholder="Search..."
                     className="input-root input-input"
                     color="secondary"
                     inputProps={{ "className": "search-bar-input", "aria-label": "search" }}
                     InputProps={{
                        className: "search-bar-input",
                     }}
                  />

               }
               {recentSearches1.length > 0 && showSuggestions && <div className="recent-searches-tab-nav">
                  <Box className="recent-searches-label">
                     <Typography className="recent-search-text">
                        Recent Searches
                     </Typography>
                     <div className="recent-search-divider-nav"></div>
                  </Box>
                  <Box>
                     {recentSearches1.slice(0, 10).map(searchText => {
                        return <div className="recent-search-card" onClick={() => selectRecents(searchText)}>{searchText}</div>
                     })}
                  </Box>
               </div>}
            </div>
            <div className="landing-btn">
               <Button variant="contained" color="primary" className="search-btn" onClick={doSearch}>
                  Search
               </Button>
            </div>
         </div>
      </div>
      : <div className="nav-center"></div>;

   return (
      <ThemeProvider theme={customTheme}>
         {showSuggestions && <div className="recent-searches-tab-hidden" onClick={() => setShowSuggestions(false)} ></div>}
         <AppBar position="static">
            <Toolbar>
               <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} lg={3}>
                     <div className="header-title u-paddingVertical-1" onClick={handleClick} onKeyDown={handleClick} tabIndex={0}>
                        <div className="main-image">
                           <img width="278" height="28" alt="AS logo" src={String(logo)} />
                        </div>
                     </div>
                  </Grid>
                  <Hidden mdDown>
                     <Grid item lg={5}>
                        {searchEnsemble}
                     </Grid>
                  </Hidden>
                  <Grid item xs={12} sm={6} lg={4}>
                     <div className="header-links">
                        <div className="nav-color u-flexItem u-flexAlignJustifyContentEnd">
                           <RouterLink exact to="/cart" className="nav-color link-item txt-link">
                              <Badge badgeContent={cartCount} color="secondary">
                                 <ShoppingCartIcon />
                              </Badge>
                           </RouterLink>
                           <div className="u-padding-1" />
                           <RouterLink exact to="/assemblies" className="nav-color link-item txt-link">
                              My Projects
                           </RouterLink>
                           <div className="u-padding-1" />
                        </div>
                        {loginButton}
                        {signUpButton}
                     </div>
                  </Grid>
                  <Hidden lgUp>
                     <Grid item xs={12}>
                        {searchEnsemble}
                     </Grid>
                  </Hidden>
               </Grid>
            </Toolbar>
         </AppBar>
         <LoginDialog open={signUpOpen} isSignUp={true} handleClose={handleClose} />
         <LoginDialog open={loginOpen} isSignUp={false} handleClose={handleClose} />
      </ThemeProvider>
   );
}
