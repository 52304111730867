import { Grid, Typography, Divider, Paper } from '@material-ui/core';
import React from 'react';

export default function PrivacyPolicyPage() {
 
  return (
    <div className="page-offset account-page">
      <Grid container item justify="center" xs={12} spacing={2}> {/* main page */}
        <Grid item xs={12}> {/* title */}
          <Typography variant="h5" noWrap>
             Privacy Policy
          </Typography>
        </Grid>

        <Grid item xs> {/* main page */}
          <Paper elevation={3} className="order-history-paper">
            <div className="loading-screen">
              <Typography variant="subtitle1" noWrap align="center">
                under construction! come back later :)
              </Typography>
            </div>
          </Paper>
        </Grid>

        <Grid item container xs={12} lg={2}>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs>
            <Typography align="center">
             ads
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
