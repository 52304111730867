import React, {useContext, useState} from "react";

import Button, {ButtonProps} from "@material-ui/core/Button";
import {
   DialogContent, DialogActions, Dialog,
   Typography, DialogTitle, CircularProgress,
} from "@material-ui/core";

import {AppContext} from "../../../contexts/AppContext";
import StripeDAO from "../../../model/dao/StripeDAO";

type Props = ButtonProps & {
  paymentId: string | undefined,
  reloadFunction: any,
}

export default function PaymentDeletionButton(props:Props) {
   const context = useContext(AppContext);

   const [open, setOpen] = React.useState(false);
   const [loading, setLoading] = useState<boolean>(false);

  // ------------- show/hide dialogue ------------- //

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // ------------- cancel order ------------- //
  const deletePayment = async () => {
    if(props.paymentId !== undefined) {
      setLoading(true);
      await StripeDAO.deleteCard(context.token, props.paymentId);
      setLoading(false);
      handleClose();
      props.reloadFunction();
    }
  }

   return (
      <div>
         <Button variant="outlined" onClick={handleClickOpen}>
            delete card
          </Button>

         <Dialog open={open} maxWidth="lg" disableBackdropClick disableEscapeKeyDown keepMounted>
            <DialogTitle>
               <Typography variant="h5" color="primary">
                  Delete Card
               </Typography>
            </DialogTitle>
            {
              (loading)
              ?
                <DialogContent dividers>
                  <Typography variant="body1" noWrap align="center">
                    <CircularProgress size={'1em'}/>
                  </Typography>
                </DialogContent>
              :
                <DialogContent dividers>
                  <Typography variant="body1">
                      Are you sure you would like to delete this card?
                  </Typography>
                </DialogContent>
            }
            <DialogActions>
              <Button variant="outlined" onClick={handleClose} disabled={loading}>
                  keep card
              </Button>
              <Button variant="contained" color="primary" onClick={deletePayment} disabled={loading}>
                  delete card
              </Button>
            </DialogActions>
         </Dialog>
      </div>
   );
}
