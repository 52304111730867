import React, {useContext} from "react";
import { BrowserRouter, Route, Switch, Redirect, RouteProps } from "react-router-dom";

import { Container } from "@material-ui/core";

import {AppContext} from "../contexts/AppContext";

import LandingPage from "../pages/landing/LandingPage";
import NavWrap from './NavWrap';
import CheckoutNavWrap from './CheckoutNavWrap';
import CategoriesPage from '../pages/categories/CategoriesPage';
import SearchPage from '../pages/search/SearchPage';
import ProjectsPage from '../pages/projects/ProjectsPage';
import ProjectPage from '../pages/projects/ProjectPage';
import CartPage from '../pages/cart/CartPage';
import ItemPage from '../pages/search/ItemPage';
import CheckoutPage from '../pages/checkout/CheckoutPage';
import OrderHistoryPage from '../pages/account/OrderHistoryPage';
import OrderPage from '../pages/account/OrderPage';

import Footer from './Footer';
import BreadcrumbWrapper from '../pages/categories/BreadcrumbWrapper';
import AccountSettingsPage from '../pages/account/AccountSettings';
import AddressPage from '../pages/account/AccountAddressPage';
import PaymentPage from '../pages/account/AccountPaymentsPage';
import {LockScreen} from '../pages/landing/LockScreen';
import AboutUsPage from "../pages/about-us/AboutUsPage";
import PrivacyPolicyPage from "../pages/privacy-policy/PrivacyPolicyPage";
import PageNotFoundPage from "../pages/404-page/404Page";
import TradesPage from "../pages/trades/TradesPage";
import BOMSearchPage from "../pages/search/BOMSearchPage";
import SearchPageTwo from "../pages/search2/SearchPage2";

interface CustomRouteProps extends RouteProps {
   Component: (props: any) => JSX.Element;
   secure: boolean;
}

const PrivateRoute = (props : CustomRouteProps) => {
   const {Component, secure} = props;

   return (<Route {...props} render={(routeProps) => (
      true
         ? <Component {...props} {...routeProps} />
         // : <Component {...props} {...routeProps} />
         : <Redirect to={{
            pathname: '/lockscreen',
            state: { referrer: routeProps.location }
          }} />
   )} />)
}

const addPageWrap = (element: JSX.Element) => {
   return () => (
      <div>
         <div className="App">
            {element.type === CheckoutPage ? <CheckoutNavWrap/> : <NavWrap/>}
            <section>
               <Container maxWidth="xl" className="main-text">
                  {element}
               </Container>
            </section>
         </div>
         <Footer/>
      </div>
   );
 }

export default function RouterLayer() {
   const context = useContext(AppContext);
   return (
      <BrowserRouter>
         <Switch>
            <Route exact path="/lockscreen" component={LockScreen}/>
            <PrivateRoute exact path="/" Component={addPageWrap(<LandingPage/>)} secure={context.secure} />
            <PrivateRoute exact path="/search" Component={addPageWrap(<SearchPage/>)} secure={context.secure} />
            <PrivateRoute exact path="/bom" Component={addPageWrap(<BOMSearchPage/>)} secure={context.secure} />
            <PrivateRoute exact path="/categories" Component={addPageWrap(<CategoriesPage/>)} secure={context.secure} />
            <PrivateRoute path="/categories/:id" Component={addPageWrap(<BreadcrumbWrapper/>)} secure={context.secure} />
            <PrivateRoute exact path="/trades" Component={addPageWrap(<TradesPage/>)} secure={context.secure} />
            <PrivateRoute exact path="/assemblies" Component={addPageWrap(<ProjectsPage/>)} secure={context.secure} />
            <PrivateRoute exact path="/assemblies/:id" Component={addPageWrap(<ProjectPage/>)} secure={context.secure} />
            <PrivateRoute exact path="/item/:id" Component={addPageWrap(<ItemPage/>)} secure={context.secure} />
            <PrivateRoute exact path="/cart" Component={addPageWrap(<CartPage/>)} secure={context.secure} />
            <PrivateRoute path="/checkout" Component={addPageWrap(<CheckoutPage/>)} secure={context.secure} />
            <PrivateRoute exact path="/orders" Component={addPageWrap(<OrderHistoryPage/>)} secure={context.secure} />
            <PrivateRoute exact path="/orders/:id" Component={addPageWrap(<OrderPage/>)} secure={context.secure} />
            <PrivateRoute exact path="/settings" Component={addPageWrap(<AccountSettingsPage/>)} secure={context.secure} />
            <PrivateRoute exact path="/addresses" Component={addPageWrap(<AddressPage/>)} secure={context.secure} />
            <PrivateRoute exact path="/payments" Component={addPageWrap(<PaymentPage/>)} secure={context.secure} />
            <PrivateRoute exact path="/about-us" Component={addPageWrap(<AboutUsPage/>)} secure={context.secure} />
            <PrivateRoute exact path="/privacy-policy" Component={addPageWrap(<PrivacyPolicyPage/>)} secure={context.secure} />
            <PrivateRoute Component={addPageWrap(<PageNotFoundPage/>)} secure={context.secure} />
         </Switch>
      </BrowserRouter>
   );
}
