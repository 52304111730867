import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
   Grid,
   Typography,
} from '@material-ui/core';
import SearchContainer from './SearchContainer';
import { useGlobalContexts } from '../../hooks';
import ResultDAO from "../../model/dao/ResultDAO";

export default function SearchPageTwo() {
   const {queryParams} = useGlobalContexts();

   let history = useHistory();
   let location = useLocation();

   // useQueryParamsFromURL(() => history.push({
   //    pathname: "/search",
   //    search: location.search,
   // }))

   return (
      <div className="page-offset">
            <Grid container spacing={3}>
               <Grid item xs={12}>
                  <div className="page-title">
                     <Typography variant="h4" noWrap>
                        {'Search Results for "' + queryParams.searchText + '"'}
                     </Typography>
                  </div>
               </Grid>
            </Grid>
            <SearchContainer daoFn={ResultDAO.searchItems} />
      </div>
   );
}
