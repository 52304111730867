import { buildDefaultQueryParams } from "../../model/interface/ModelBuilder";
import { QueryParamsModel } from "../../model/interface/Models";

type Param
= string[]
| string
| number
| null;

export type QueryParams = {
   [param: string]: Param
};
export type Params = { [param: string]: string };

type DAOQueryParams = { [param: string]: string[] };

/*
   Joins together the query parameters and their values into one string
   to be attached to the end of a URL
   Example
      input:
      queryParams = {
         perPage: 20,
         searchText: "Aearo",
      }
      output:
         "?perPage=20&searchText=Aearo"
   */
export function getQueryParamString(queryParams: QueryParams): string {
   const paramStrings = [];

   for (let [paramName, paramValues] of Object.entries(queryParams)) {
      if (!Array.isArray(paramValues)) {
         if (paramValues &&
            ((typeof paramValues === "number" && !isNaN(paramValues)) ||
            (typeof paramValues === "string" && paramValues !== ""))) {
            paramValues = [paramValues.toString()]; // convert numbers and strings to param array
         } else {
            paramValues = [];
         }
      }

      for (let paramValue of paramValues) {

         if (Array.isArray(paramValue)) {
            paramValue.forEach(param => {
               paramStrings.push(`${paramName}=${param}`);
            })
         }

         else
            paramStrings.push(`${paramName}=${paramValue}`);
      }
   }

   const fullParamString = encodeURI(paramStrings.join("&"));

   return (fullParamString.length > 0) ? `?${fullParamString}` : "";
}

/*
   Creates a query param model object from a query string from a url
   Example
      input:
         "?perPage=20&searchText=Aearo"
      output:
         queryParams = {
            perPage: 20,
            searchText: "Aearo",
         }
         
*/
export function getQueryParamObject(queryString: string): QueryParamsModel {
   const paramObj = buildDefaultQueryParams();

   let params = decodeURI(queryString).split("?")[1].split("&");

   console.log("params: " + params.toString());

   for (const param of params) {
      const parts = param.split("=");
      const key = parts[0];
      const val = parts[1];
      switch (key) {
         case "searchText":
            paramObj.searchText = val;
         case "page":
            paramObj.page = parseInt(val) || 1;
         case "perPage":
            paramObj.perPage = parseInt(val) || 20;
         case "sort":
            paramObj.sort = val;
         default:
            if (paramObj.filters[key] && paramObj.filters[key].length) {
               paramObj.filters[key] = paramObj.filters[key].concat([val]);
            } else {
               paramObj.filters[key] = [val];
            }
      }
   }

   return paramObj;
}

/**
 * Converts a defined type with no index signature 
 * to a dict and returns a wrapped function.
 * 
 * Allows calling dao functions with a dict-like query params object
 * using a well defined model query params object from a hook.
 */
export function wrapWithIndexSignature(daoFn: Function) {
   return async (definedModel: QueryParamsModel) => {
      let qps: DAOQueryParams = {};
   
      for (const [key, val] of Object.entries(definedModel)) {
         if (typeof val === 'object' && val !== null) {
            qps = {...qps, ...val};
         } else {
            qps[key] = [val.toString()];
         }
      }

      const response = await daoFn(qps);
      return response;
   }
}