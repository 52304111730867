import {useState} from "react";
import {useMutation} from "react-query";

import { useGlobalContexts } from "..";
import { DBFilter, DBSearchResults } from "../../model/interface/DBModels";
import { QueryParamsModel } from "../../model/interface/Models";
import { SearchResultHook } from "../hook";

/**
 * Creates a hook for managing search results.
 * @param getResults - Returns search results.
 */
export default function useSearchResult<T>(
   getResults: (queryParams: QueryParamsModel) => Promise<DBSearchResults<T>>,
): SearchResultHook<T> {
   const {queryParams} = useGlobalContexts();
   const [results, setResults] = useState<T[]>([]);
   const [filters, setFilters] = useState<DBFilter[]>([]);
   const [resultCount, setResultCount] = useState(0);
   const [page, setPage] = useState(queryParams.page);
   const [perPage, setPerPage] = useState(queryParams.perPage);
   const [maxPages, setMaxPages] = useState(0);

   const {mutate: doSearch, isLoading, isError} = useMutation(
      () => getResults(queryParams),
      {
         onSuccess: (data: DBSearchResults<T>) => {
            const {results, filters, resultCount, currentPage, maxPages} = data;
            setResults(results);
            if (filters) {
              setFilters(filters);
            }
            setResultCount(resultCount);
            setPage(currentPage);
            setMaxPages(maxPages);
         },
      },
   );

   return {
      results,
      resultCount,
      filters,
      page: page === null ? 1 : page,
      maxPages,
      perPage,
      doSearch,
      isLoading,
      isError,
  };
}
