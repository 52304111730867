import React from 'react';

import OrderDetails from "../../components/account/orderhistory/OrderDetails"
import AccountPageWrapper from '../../components/account/AccountPageWrapper';

export default function OrderPage() {
 
  return (
    // <div className="page-offset">
    //   <Grid container item justify="center" xs={12}> {/* main page */}
    //     <Grid item xs={12}> {/* title */}
    //       <Typography variant="h5" noWrap>
    //          Order History
    //       </Typography>
    //     </Grid>
    //     <OrderList/>
    //   </Grid>
    // </div>
    <AccountPageWrapper
      title={"Order History"}
      selected={0}
      inside={<OrderDetails />}/>
  );
}
