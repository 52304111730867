import Endpoint from "../request/Endpoint";

import DAO from "./DAO";
import {
   DBSearchResults,
   DBAssembly,
   DBItem,
   DBCartItem,
   DBAbstractItem,
   DBOrder,
   DBOrderedItem,
   DBCategories,
} from "../interface/DBModels";


type QueryParams = { [param: string]: string[] };

class ResultDAO extends DAO {

   private readonly cartEndpoint: Endpoint<{}, DBSearchResults<DBCartItem>>;
   private readonly assembliesEndpoint: Endpoint<{}, DBSearchResults<DBAssembly>>;
   private readonly assemblyItemsEndpoint: Endpoint<{}, DBSearchResults<DBItem>>;
   private readonly searchEndpoint: Endpoint<{}, DBSearchResults<DBAbstractItem>>;
   private readonly ordersEndpoint: Endpoint<{}, DBSearchResults<DBOrder>>;
   private readonly orderItemsEndpoint: Endpoint<{}, DBSearchResults<DBOrderedItem>>;
   private readonly subcategoriesEndpoint: Endpoint<{}, DBCategories>;
   
   constructor() {
      super();

      this.cartEndpoint = Endpoint.for<{}, DBSearchResults<DBCartItem>>('cart');
      this.assembliesEndpoint = Endpoint.for<{}, DBSearchResults<DBAssembly>>('assemblies');
      this.assemblyItemsEndpoint = Endpoint.for<{}, DBSearchResults<DBItem>>('assemblyItems');
      this.searchEndpoint = Endpoint.for<{}, DBSearchResults<DBAbstractItem>>('search');
      this.ordersEndpoint = Endpoint.for<{}, DBSearchResults<DBOrder>>('orders');
      this.orderItemsEndpoint = Endpoint.for<{}, DBSearchResults<DBOrderedItem>>('ordersById');
      this.subcategoriesEndpoint = Endpoint.for<{}, DBCategories>('subcategories');
   }

   async getCart(queryParams: QueryParams = {}, token: string = ""): Promise<DBSearchResults<DBCartItem>> {
      console.log("getting cart items");
      
      const response = await this.cartEndpoint.get(
         {token},
         queryParams
      );

      this.validateResponse(response);
         
      return response;
   }
   
   async getAllAssemblies(queryParams: QueryParams = {}, token: string = ""): Promise<DBSearchResults<DBAssembly>> {
      console.log("getting assemblies");
      
      const response = await this.assembliesEndpoint.get(
         {token},
         queryParams
      );

      this.validateResponse(response);
         
      return response;
   }

   async getAssemblyItems(queryParams: QueryParams = {}, token: string = "", assemblyId: string): Promise<DBSearchResults<DBItem>> {
      console.log("getting items for assembly");

      const urlParams = {
         token,
         assemblyId,
      }

      const response = await this.assemblyItemsEndpoint.get(
         urlParams,
         queryParams
      );

      this.validateResponse(response);
         
      return response;
   }

   searchItems = async (queryParams: QueryParams = {}, token: string = ""): Promise<DBSearchResults<DBAbstractItem>> => {
      console.log("searching");
      
      const response = await this.searchEndpoint.get(
         {token},
         queryParams,
      );

      this.validateResponse(response);
         
      return response;
   }

   getSubcategoryItems = (path: string) => async (queryParams: QueryParams = {}, token: string = ""): Promise<DBSearchResults<DBAbstractItem>> => {
      console.log("getting subcategory items");

      const urlParams = {
         token,
         path: path,
      }

      const response: DBCategories = await this.subcategoriesEndpoint.get(
         urlParams,
         queryParams,
      );

      this.validateResponse(response);
      
      const empty: DBSearchResults<DBAbstractItem> = {
         results: [],
         resultCount: 0,
         maxPages: 1,
         currentPage: 1,
         recent_searches: [], 
         purchase_history: []
      }

      return response.exactItems || empty;
   }

   async getAllOrders(queryParams: QueryParams = {}, token: string = ""): Promise<DBSearchResults<DBOrder>> {
      console.log("getting orders");
      
      const response = await this.ordersEndpoint.get(
         {token},
         queryParams
      );

      this.validateResponse(response);
         
      return response;
   }

   async getOrderItems(queryParams: QueryParams = {}, token: string = "", orderId: string): Promise<DBSearchResults<DBOrderedItem>> {
      console.log("getting items for order: " + orderId);

      const urlParams = {
         token,
         orderId,
      }

      const response = await this.orderItemsEndpoint.get(
         urlParams,
         queryParams
      );

      this.validateResponse(response);
         
      return response;
   }

}

const instance = new ResultDAO();

Object.freeze(instance);

export default instance;