import React, {useState, useContext} from "react";
import {
   useParams,
   Link as RouterLink,
} from 'react-router-dom';

import {
   Breadcrumbs,
   CircularProgress,
   Divider,
   Grid, Hidden, Paper, Typography,
} from "@material-ui/core";
import Link, { LinkProps } from '@material-ui/core/Link';

import {AppContext} from "../../contexts/AppContext";
import PriceComparison from "./components/PriceComparison";
import { DBExactItemGrouping } from "../../model/interface/DBModels";
import ItemDAO from "../../model/dao/ItemDAO";
import PriceHistory from "./components/PriceHistory";

interface LinkRouterProps extends LinkProps {
   to: string;
   replace?: boolean;
 }

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

export default function ItemPage() {
   const { id } = useParams<{id:string}>();

   const context = useContext(AppContext);
   const [item, setItem] = useState<DBExactItemGrouping>();
   const [isLoading, setLoading] = useState(true);
   const [errorMessage, setError] = useState(false);

   React.useEffect(() => {
      let isActive = true;

      setLoading(true);
      setError(false);
      
      console.log("getting items for " + id);

      ItemDAO.getItemDetails(context.token, id)
      .then((item) => {
            if (isActive) {
               setItem(item);
               setLoading(false);
               setError(false);
            }
      }).catch(() => {
            if (isActive) {
               setError(true);
               setLoading(false);
            }
      });

      return () => {
      isActive = false;
      };
   }, [id]);

   return (
      <div className="page-offset">
         <Grid container spacing={2}>
            {!isLoading && item && item.urlPath ?
               <Breadcrumbs aria-label="breadcrumb">
                  <LinkRouter color="inherit" to="/categories">
                     All Products
                  </LinkRouter>
                  {item.urlPath.split("/").map((value, index) => {
                     const to = `/categories/${item.urlPath.split("/").slice(0, index + 1).join("/")}`;
                     
                     return (
                        <LinkRouter color="inherit" to={to} key={to}>
                           {value.replace(/-/g, " ")}
                        </LinkRouter>
                     );
                  })}
               </Breadcrumbs>
            :
               <div></div>
            }
            <Grid item xs={12}>
               {/* Title */}
               {isLoading ?
                  <CircularProgress size={"2em"}/>
               :
                  <Typography variant="h4" color="primary">
                     {item?.name}
                  </Typography>
               }
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
               {/* Picture, Desc */} 
               <Paper elevation={2} >
                  {isLoading ?
                     <div></div>
                  :
                     <Grid container direction="column" alignItems="flex-start">
                        <Grid item xs={12} className="u-flexSelfCenter">
                           <img className="u-paddingTop-1 item-details-image" src={item?.image}/>
                        </Grid>
                        <Grid className="u-paddingLeft-1" item xs={12}>
                           {item?.individualItems[0]?.specs?.length ?
                           <Typography variant="h6" color="primary">
                              Specifications Overview
                           </Typography>
                           : <div></div>}
                           {item?.individualItems[0]?.specs?.map((spec) => 
                              <div key={spec.key}>
                              <Typography variant="body1" color="secondary">
                              {spec.key + ": " + spec.value}
                              </Typography>
                              </div>
                              )}
                        </Grid>
                     </Grid>
                  }
               </Paper>
            </Grid>
            <Grid item xs={12} md={8} lg={7}>
               <Paper elevation={2}>
                  {/* Price Comparison */}
                  <div className="u-padding-2">
                     <Typography variant="h6" color="secondary">
                        Price Comparison
                     </Typography>
                  </div>
                  {isLoading ?
                     <div></div>
                  :
                     item && <PriceComparison items={item.individualItems} />
                  }
                  {/* Product Specs */}
                  {/* Price History */}
                  {
                     item && <PriceHistory items={item.individualItems}/>
                  }
               </Paper>
            </Grid>
            <Hidden mdDown>
               <Grid item lg={1}>
                  <Divider orientation="vertical" />
               </Grid>
               <Grid item lg={1}>
               </Grid>
            </Hidden>
         </Grid>
      </div>
   )
}
