import React from 'react';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Divider
} from '@material-ui/core';

import AccountSidebar from '../../components/account/AccountSidebar';

type Props = {
  title?: string,
  selected: number,
  inside: any,
};

export default function AccountPageWrapper(props: Props) {
  return (
    <div className="page-offset account-page">
      <Grid container item justify="center" xs={12} spacing={2}> {/* main page */}
        <Grid item xs={12}> {/* title */}
          <Typography variant="h5" noWrap>
             My Account: {props.title}
          </Typography>
        </Grid>
        <AccountSidebar selected={props.selected}/>
        {props.inside}
        <Grid item container xs={12} lg={2}>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs>
            <Typography align="center">
             ads
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
