import React, { useState, useContext } from 'react';
import { useSnackbar } from 'notistack';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Button,
   TableContainer, Table, TableBody,
   Paper, Snackbar, CircularProgress, Hidden
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {AppContext} from '../../contexts/AppContext';
import { DBCartItem } from '../../model/interface/DBModels';
import ResultDAO from "../../model/dao/ResultDAO";
import CartDAO from "../../model/dao/CartDAO";
import ItemRow from './ItemRow'

type Props = {
  cartChanged?: boolean,
  setCartChanged?: any,
  priceChanged?: boolean,
  setPriceChanged?: any,
};

type ItemInfo = {
  quantity?: number;
  itemId: string;
}

export default function CartItems(props: Props) {
   const context = useContext(AppContext);
   // selectedItems: list of INDEXES selected relative to the cart
   const [selectedItems, setSelectedItems] = useState<number[]>([]);
   // removedItems: list of ITEMS removed so they can be re-added

   const {enqueueSnackbar, closeSnackbar} = useSnackbar();

   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(false);

   const [cartItems, setCartItems] = useState<DBCartItem[]>([]);

   React.useEffect(() => {
    let isActive = true;
    
    setLoading(true);
    setError(false);

    ResultDAO.getCart({}, context.token)
      .then((cartItems) => {
          if (isActive) {
            setCartItems(cartItems.results);
            setLoading(false);
            setError(false);
          }
      }).catch(() => {
          if (isActive) {
            setError(true);
            setLoading(false);
          }
      });

    return () => {
      isActive = false;
    };
  }, [props.cartChanged]);

  React.useEffect(() => {
    toggleCartChanged();
  }, [context.token]);

  const toggleCartChanged = (value?: boolean) => {
    if(value === undefined) {
      props.setCartChanged(!props.cartChanged);
    }
    else {
      props.setCartChanged(value);
    }
  }

  const togglePriceChanged = () => {
    props.setPriceChanged(!props.priceChanged);
  }

  // --- functions that return true/false  ---
  const isSelected = (itemIndex: number) => {
    return(selectedItems.indexOf(itemIndex) !== -1);
  }

  const getItemId = (itemIndex: number) => {
    return(cartItems[itemIndex].item.id);
  }

  // --- functions for selecting items ---
  const selectItem = (itemIndex: number) => {
    // check if item is selected already
    const indexCheck = selectedItems.indexOf(itemIndex);
    if (indexCheck === -1) {
      // just throw it on the end
      setSelectedItems([...selectedItems, itemIndex]);
    }
    else if (indexCheck === 0) {
      setSelectedItems(selectedItems.slice(1));
    }
    else if (indexCheck === selectedItems.length - 1) {
      // remove the end
      setSelectedItems(selectedItems.slice(0, indexCheck));
    }
    else {
      let newSelectedItems: number[] = [];
      newSelectedItems = newSelectedItems.concat(
        selectedItems.slice(0, indexCheck),
        selectedItems.slice(indexCheck + 1)
      );
      setSelectedItems(newSelectedItems);
    }
  }

  const selectAllItems = () => {
    return () => {
      if(selectedItems.length === cartItems.length) {
        // we have items, deselect them
        setSelectedItems([]);
      }
      else {
        // select everything from scratch
        let newSelectedItems: number[] = [];
        for(let i = 0; i < cartItems.length; i++) {
          newSelectedItems.push(i)
        }
        setSelectedItems(newSelectedItems);
      }
    }
  }

  // --- function for editing quantity---
  const editItem = async (itemIndex: number, qty: number) => {
    const itemId = getItemId(itemIndex);
    cartItems[itemIndex].quantity = qty;
    await CartDAO.updateQty({quantity: qty}, context.token, itemId)
    togglePriceChanged();
  }

  // --- functions for removal/undoing removals ---
  const removeItem = async (itemIndex: number) => {
    setLoading(true)
    let removedItem = cartItems[itemIndex];
    const tempRemoved: ItemInfo = {quantity: cartItems[itemIndex].quantity, itemId: getItemId(itemIndex)}
    await CartDAO.removeItems(context.token, [tempRemoved]);
    const action = (key: any) => (
      <Button onClick={() => undoRemoval([removedItem], key)}>
        undo
      </Button>
    );
    enqueueSnackbar("Items removed from cart.", {variant: "default", action})
    toggleCartChanged();
  }

  const removeSelectedItems = async () => {
    setLoading(true);
    // let tempSelected = selectedItems;
    let itemsToRemove: DBCartItem[] = []; // stores the actual items
    let itemInfosToRemove: ItemInfo[] = []; // stores qty + id
    for (var i = 0; i < selectedItems.length; i++) {
      let cartIndex = selectedItems[i]; // index relative to cart
      let removedItem = {quantity: cartItems[cartIndex].quantity, itemId: getItemId(cartIndex)}
      itemInfosToRemove.push(removedItem);
      itemsToRemove.push(cartItems[cartIndex]);
    }
    await CartDAO.removeItems(context.token, itemInfosToRemove);
    const action = (key: any) => (
      <Button onClick={() => undoRemoval(itemsToRemove, key)}>
        undo
      </Button>
    );
    enqueueSnackbar("Items removed from cart.", {variant: "default", action})
    setSelectedItems([]);
    toggleCartChanged();
  }

  const undoRemoval = async (itemsRemoved: DBCartItem[], key: any) => {
    setLoading(true)
    let itemsToAdd: {quantity: number, itemId: string}[] = [];
    for (var i = 0; i < itemsRemoved.length; i++) {
      const addedItem = {quantity: itemsRemoved[i].quantity, itemId: itemsRemoved[i].item.id}
      itemsToAdd.push(addedItem)
      console.log(addedItem)
    }
    await CartDAO.addItems(context.token, itemsToAdd);
    toggleCartChanged(true); // this needs to be true or else it freezes
    closeSnackbar(key);
    
  }

  // --- lines of text/things that change with state ---

  const selectedRemoveButton =
    (selectedItems.length === 0) ? ""
    : <Button size="small" onClick={() => removeSelectedItems()}>Remove Selected</Button>;

  const selectAllButtonText =
    (cartItems !== undefined)
    ? (selectedItems.length === cartItems.length) ? "Deselect all items" : "Select all items"
    : ""

  const selectedItemsText =
    (selectedItems.length === 0) ? "No items"
    : (selectedItems.length === 1) ? "1 item"
    : (selectedItems.length).toString() + " items"
  ;

   return (
     <div> {/* outer wrapper */}
        <Grid item container justify="space-between" xs={12}> {/* line under title */}
          <Hidden mdDown> {/* large version */}
            <Typography variant="subtitle1">
              {selectedItemsText} selected. <Button onClick={selectAllItems()}>{selectAllButtonText}</Button> {selectedRemoveButton}
            </Typography>
          </Hidden>
          <Hidden lgUp> {/* small version */}
            <Grid item>
              <Typography variant="subtitle1">
                {selectedItemsText} selected.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                <Button size="small" onClick={selectAllItems()}>{selectAllButtonText}</Button>
              </Typography>
              <Typography variant="subtitle1" align="right">
                {selectedRemoveButton}
              </Typography>
            </Grid>
          </Hidden>
       </Grid>
       <Paper elevation={3}> {/* items in cart */}
       {
          loading
          ?
          <div className="loading-screen">
            <Typography variant="subtitle1" noWrap align="center">
              <CircularProgress size={'1em'}/>
              <br/>
              Loading cart...
            </Typography>
          </div>
          :
            cartItems.length > 0
            ?
            <TableContainer>
              <Table>
                <TableBody>
                  {
                    cartItems.map((dbItem: DBCartItem, index) => {
                      return (
                        <ItemRow
                          item={dbItem.item}
                          quantity={dbItem.quantity}
                          index={index}
                          selectItem={selectItem}
                          editItem={editItem}
                          removeItem={removeItem}
                          isSelected={isSelected(index)}
                          key={String(dbItem.quantity) + String(index)}
                        />
                      )}
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            :
            <div className="loading-screen">
              <Typography variant="subtitle1" noWrap align="center">
                Your cart is empty!
              </Typography>
            </div>
          }
       </Paper>
     </div>
   );
}
