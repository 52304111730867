import DAO from "./DAO";
import Endpoint from "../request/Endpoint";
import {
   DBAssembly,
} from "../interface/DBModels";

type AssemblyInfo = {
   name: string;
}

type ItemInfo = {
   id: string;
}

class AssemblyDAO extends DAO {
   private readonly newAssemblyEndpoint: Endpoint<AssemblyInfo, DBAssembly>;
   private readonly addItemsEndpoint: Endpoint<ItemInfo[], {}>;
   private readonly updateAssemblyInfoEndpoint: Endpoint<AssemblyInfo, DBAssembly>;
   private readonly deleteAssemblyEndpoint: Endpoint<{}, {}>;
   private readonly removeItemsEndpoint: Endpoint<ItemInfo[], {}>;
   private readonly namesOnlyEndpoint: Endpoint<{}, DBAssembly[]>;

   constructor() {
      super();

      this.newAssemblyEndpoint = Endpoint.for<AssemblyInfo, DBAssembly>('assemblyPost');
      this.addItemsEndpoint = Endpoint.for<ItemInfo[], {}>('assemblyById');
      this.updateAssemblyInfoEndpoint = Endpoint.for<AssemblyInfo, DBAssembly>('assemblyById');
      this.deleteAssemblyEndpoint = Endpoint.for<{}, {}>('assemblyById');
      this.removeItemsEndpoint = Endpoint.for<ItemInfo[], {}>('assemblyRemoveItem');
      this.namesOnlyEndpoint = Endpoint.for<{}, DBAssembly[]>('assemblyNames');
   }

   async createAssembly(assemblyName: AssemblyInfo, token: string = ""): Promise<DBAssembly> {
      console.log("creating assembly");

      const response = await this.newAssemblyEndpoint.post(
         assemblyName,
         {token},
      );

      this.validateResponse(response);

      return response;
   }

   async addItems(token: string = "", assemblyId: string, itemIds: ItemInfo[]) {
      console.log("adding item to assembly");

      const urlParams = {
         token,
         assemblyId,
      }

      const response = await this.addItemsEndpoint.post(
         itemIds,
         urlParams,
      );

      this.validateResponse(response);
   }

   async renameAssembly(data: AssemblyInfo, token: string = "", assemblyId: string): Promise<DBAssembly> {

      const urlParams = {
         token,
         assemblyId,
      }

      const response = await this.updateAssemblyInfoEndpoint.put(
         data,
         urlParams,
      );

      this.validateResponse(response);

      return response;
   }

   async deleteAssembly(token: string = "", assemblyId: string) {
      const urlParams = {
         token,
         assemblyId,
      }

      const response = await this.deleteAssemblyEndpoint.delete(urlParams);

      if (response) {
         this.validateResponse(response);
      }
   }

   async removeItems(token: string = "", assemblyId: string, itemIds: ItemInfo[]) {
      const urlParams = {
         token,
         assemblyId,
      }

      const response = await this.removeItemsEndpoint.post(itemIds, urlParams);

      if (response) {
         this.validateResponse(response);
      }
   }

   async getNamesOnly(token: string = ""): Promise<DBAssembly[]> {
      const urlParams = {
         token,
      }

      const response = await this.namesOnlyEndpoint.get(urlParams);

      if (response) {
         this.validateResponse(response);
      }

      return response;
   }
}

const instance = new AssemblyDAO();

Object.freeze(instance);

export default instance;
