// reference: https://github.com/manishsaraan/email-validator/blob/master/index.js

export function isValidEmailAddress(email: string): boolean {
  var tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  // basics
  if(email.length <= 0) return false;
  if(email.indexOf("@") === -1) return false;
  if(email.indexOf(".") === -1) return false;

  // check for 2 parts
  let firstSplit = email.split("@");
  if(firstSplit.length !== 2) return false;

  // check user length
  let user = firstSplit[0];
  if(user.length <= 0 || user.length > 64) return false;

  // check length of remaining
  let tempWebsiteTLD = firstSplit[1];
  if(tempWebsiteTLD.length > 255) return false;

  // check for domain + tld
  let secondSplit = firstSplit[1].split(".")
  if(secondSplit.length <= 1) return false;

  // check website length
  let website = secondSplit[0];
  if(website.length <= 0) return false;

  // check tld length
  let tld = secondSplit[1];
  if(tld.length <= 0) return false;

  // regex stuff i guess
  if (!tester.test(email)) return false;

  return true;
}
