import React, {createContext, useEffect, useState} from 'react';
import SearchListenerCollection from '../pages/search/SearchListenerCollection';
import {Credentials, Account} from "../model/interface/DBModels";
import {AccountHook, QueryParamHook, CartHook} from "../hooks/hook";
import { buildEmptyAccount, buildDefaultQueryParams } from '../model/interface/ModelBuilder';
import { useQueryParams } from '../hooks';
import UserDAO from '../model/dao/UserDAO';

export const AppContext = createContext({
   token: "",
   account: {},
   setCredentials: (credObj: Credentials, cb: () => void = () => {}) => {},
   searchListener: new SearchListenerCollection(''),
   searchText: "",
   setSearchText: (searchText: string) => {},
   signOut: () => {},
   toggleCartUpdate: () => {},
   secure: false,
   setSecure: (secure: boolean) => {}
});

export const AppProvider: React.FunctionComponent = (props) => {
   const [token, setToken] = useState("");
   const [account, setAccount] = useState<Account>({id: "", email: "", firstName: "", lastName: "", guest: true});
   const [searchListener, setSearchListener] = useState(new SearchListenerCollection(""));
   const [searchText, setSearchText] = useState("");
   const [secure, setSecure] = useState(false);

   const setCredentials = (credObj: Credentials) => {
      console.log("about to set creds: " + credObj.token);
      if(credObj.token == null || credObj.account == null)
         return;
      setToken(credObj.token);
      setAccount(credObj.account);
      setSearchListener(new SearchListenerCollection(credObj.token))
   }

   const removeToken = () => {
      setToken("");
      setSearchListener(new SearchListenerCollection(""))
      setAccount({id: "", email: "", firstName: "", lastName: "", guest: true});
      // remove token
      localStorage.setItem("AS token", "");
   }

   const toggleCartUpdate = () => {}

   const checkIfLoggedIn = async () => {
      if (token || account.email) {
         return;
      }

      const currentToken = localStorage.getItem("AS token");
      if (currentToken && currentToken != "" && currentToken != "undefined") {
         setToken(currentToken);
         setAccount(await UserDAO.getAccountInfo(currentToken));
      }
   }

   useEffect(() => {
      checkIfLoggedIn();
   })


   return (
      <AppContext.Provider value={{
         token: token,
         account: account,
         setCredentials: setCredentials,
         searchListener: searchListener,
         searchText: searchText,
         setSearchText: setSearchText,
         signOut: removeToken,
         toggleCartUpdate: toggleCartUpdate,
         secure: secure,
         setSecure: setSecure,
      }}>
         {/* <AccountContext.Provider value={useAccount}> */}
            {/* <CartContext.Provider> */}
               <QueryParamContext.Provider value={useQueryParams()}>
                  {props.children}
               </QueryParamContext.Provider>
            {/* </CartContext.Provider> */}
         {/* </AccountContext.Provider> */}
      </AppContext.Provider>
   )
}


export const CartContext = createContext<CartHook>({
   toggleCartUpdate: () => {},
});

export const AccountContext = createContext<AccountHook>({
   token: "",
   account: buildEmptyAccount(),
   isLoggedIn: false,
   loginUser: () => {},
   logoutUser: () => {},
});

export const QueryParamContext = createContext<QueryParamHook>({
   queryParams: buildDefaultQueryParams(),
   updateQueryParams: () => {},
   resetQueryParams: () => {},
});