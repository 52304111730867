import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import ReactGA from "react-ga";
import * as FullStory from "@fullstory/browser";


//style import
import "./scss/main.scss";

// Google Analytics
ReactGA.initialize("UA-189305312-1");

// Fullstory
const ORG_ID = "10VYWM";
FullStory.init({orgId: ORG_ID});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
