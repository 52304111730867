import React from 'react';

import Grid from '@material-ui/core/Grid';
import {
   TextField
} from '@material-ui/core';

type Props = {
  required?: boolean,
  isError?: boolean,
  errorMessage?: string,
  size: any, // size for grid
  name?: string,
  label: string,
  value?: string,
  placeholder: string,
  autocomplete?: string,
  onChange?: any, // onChange for validation
  inputComponent?: any // input for payment methods w/ stripe
};

const CheckoutTextField = function(props: Props) {
  let textFieldId = "outlined-required";
  let textFieldRequirement = true;
  if(props.required === false) {
    textFieldId = "outlined"
    textFieldRequirement = false;
  }

  let helperText = "";
  if(props.isError === true) {
    helperText = props.errorMessage as string
  }

  return(
    <Grid item xs={12} md={props.size}>
      <TextField
        required = {textFieldRequirement}
        fullWidth

        id={textFieldId}
        name={props.name}
        label={props.label}
        value={props.value}
        placeholder={props.placeholder}
        autoComplete={props.autocomplete}

        variant="outlined"
        InputLabelProps={{ shrink: true }}
        margin='dense'
        onChange={props.onChange}
        error={props.isError}
        helperText={helperText}

        InputProps={{
          inputComponent: props.inputComponent
        }}
        inputProps={{
          "aria-label": props.label
        }}
      />
    </Grid>
  )
}

export default CheckoutTextField;
