import Endpoint from "../request/Endpoint";
import DAO from "./DAO";
import {Credentials, Login, NewAccount, UpdatedAccount, Account} from "../interface/DBModels";

type Code = {
   code: string,
}

type ResStatus = {
   status: number,
}

class UserDAO extends DAO {
   private readonly secureEndpoint: Endpoint<Code, ResStatus>;

   constructor() {
      super();

      this.secureEndpoint = Endpoint.for<Code, ResStatus>('secure');
   }
   
   async secure(code: string): Promise<boolean> {
      const response = await this.secureEndpoint.post({code}, undefined, undefined, true);

      this.validateResponse(response);
      
      return response.status === 200;
   }
}

const instance = new UserDAO();

Object.freeze(instance);

export default instance;