import ListenerCollection from "./ListenerCollection";
import SearchResults from "./SearchResults";
import SearchListener from "./SearchListener";

class SearchListenerCollection extends ListenerCollection<SearchListener> {
   token: string;
   currentFilter: SearchResults;

   constructor(token: string) {
      super();

      this.token = token;
      this.currentFilter = new SearchResults({
         token: this.token
      });
   }

   getQueryParams = () => {
      return this.currentFilter.queryParams;
   }

   update = (updatedText?: SearchResults) => {
      if(updatedText) {
         this.currentFilter = updatedText;
      }

      for(const listener of this.listeners) {
         listener.searchUpdated(this.currentFilter);
      }
   };
   
   updateText = (
      text: string,
      doUpdateListeners: boolean = true
   ) => {
      this.currentFilter.searchText = text;
      this.updateQueryParam("searchText", text);

      if(doUpdateListeners) {
         this.update();
      }
   };

   updateQueryParam = (param: string, ...value: string[]) => {
      this.currentFilter.queryParams[param] = value;
   };

   updateQueryParams = (selected: { [filterName: string]: string[] }) => {
      for (const selection in selected) {
         this.updateQueryParam(selection, ...selected[selection])
      }
      this.currentFilter.refresh();
   };

   replaceQueryParams = (params: { [name: string]: string}) => {
      let convertedParams: { [name: string]: string[]} = {};
      for (const param in params) {
         convertedParams[param] = [params[param]];
      }
      this.currentFilter.queryParams = convertedParams;
   };

   clearQueryParams = () => {
      this.currentFilter.queryParams = {};

      this.currentFilter.refresh();
   };

   initialize = (
      listener: SearchListener,
      searchFlag: boolean
   ) => {
      
   // if(Object.keys(props).length > 0) {
   //    this.currentFilter = new SearchResults({
   //       token: this.token,
   //       searchText: this.currentFilter.searchText,
   //       ...props
   //    });
   // }

      this.listen(listener);
      if (searchFlag) {
         this.update();
      }
   }

   destruct = (listener: SearchListener) => {
      this.unlisten(listener);
   }
}

export default SearchListenerCollection;