import React, { useContext } from 'react';
import {
   Link,
} from 'react-router-dom';
import {
   Location,
} from 'history';

import {
   Card,
   CardMedia,
   CardActionArea,
   CardContent,
   Grid 
} from '@material-ui/core';

import {
   DBCategory,
} from "../../model/interface/DBModels";

type Props = {
   categoryList: DBCategory[],
   fromTop?: boolean,
}

export default function CategoryGrid(props:Props) {
   const {fromTop, categoryList} = props;

   const getPath = (category:DBCategory) => {
      return fromTop ? "categories/" + category.urlPath : category.urlPath.split("/").slice(-2).join("/");
   }

   return (
      <div>
         <Grid
            container
            item
            xs={12}
            justify="flex-start"
            alignItems="flex-start"
            spacing={5}
         >
            {categoryList ? categoryList.map(
               (category, i) => (
                  <Grid item key={i} xl={2} md={3} sm={6} xs={12}>
                     <Link to={getPath(category)}>
                        <Card variant="outlined"
                           className="grid-item-display"
                           key={category.id}
                           >
                              <CardMedia
                              className="grid-item-display-image"
                              image={category.image}
                              title={category.name}/>
                           <CardActionArea>
                           <CardContent>
                              <div className="default-text">
                                 {category.name}
                              </div>
                           </CardContent>
                           </CardActionArea>
                        </Card>
                     </Link>
                  </Grid>
               )
            ) : "Nothing Here."}
         </Grid>
      </div>
   );
}