import React from 'react';

import {
  NavLink as RouterLink,
} from 'react-router-dom';

import {
   Stepper, Step, StepLabel, Link
} from '@material-ui/core';

export default function CheckoutStepper(props: any) {
  let steps = ['Shipping Address', 'Payment Method', 'Shipping', 'Review'];
  let urls = [`/checkout/`, `/checkout/payment`, `/checkout/shipping`, `/checkout/review`]

  const labelMaker = (index: number, label: string) => {
    if (props.step > index) {
      return (
        <StepLabel>
          <Link component={RouterLink} to={urls[index]} variant="body2">
            {label}
          </Link>
        </StepLabel>
      );
    }
    else {
      return (
        <StepLabel>
            {label}
        </StepLabel>
      );
    }
  }

  return (
    <div className="checkout-stepper">
      <Stepper activeStep={props.step}>
        {steps.map((label, index) => (
          <Step key={label}>
            {labelMaker(index, label)}
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
