import {useContext} from "react";

import {AppContext} from "../hook";
import {
  CartContext,
  AccountContext,
  QueryParamContext,
} from "../../contexts/AppContext";

/**
 * Creates a hook for all values in the global contexts.
 */
export default function useGlobalContexts(): AppContext {
  return {
    ...useContext(CartContext),
    ...useContext(AccountContext),
    ...useContext(QueryParamContext),
  };
}
