import DAO from "./DAO";
import Endpoint from "../request/Endpoint";

type OrderInfo = {
   paymentMethod: string;
   shippingCity: string;
   shippingCountry: string;
   shippingLine1: string;
   shippingLine2: string;
   shippingPostalCode: string;
   shippingState: string;
   shippingName: string;
   shippingPhone: string;
   status: string;
}

class OrderDAO extends DAO {
   private readonly newOrderEndpoint: Endpoint<OrderInfo, {}>;
//   private readonly updateOrderEndpoint: Endpoint<OrderInfo, DBOrder>;
   private readonly deleteOrderEndpoint: Endpoint<{}, {}>;

   constructor() {
      super();

      this.newOrderEndpoint = Endpoint.for<OrderInfo, {}>('orders');
//      this.updateOrderEndpoint = Endpoint.for<OrderInfo, DBOrder>('ordersById');
      this.deleteOrderEndpoint = Endpoint.for<{}, {}>('ordersById');
   }

   async createOrder(data: OrderInfo, token: string = "") {
      console.log("creating Order");

      const response = await this.newOrderEndpoint.post(
         data,
         {token},
      );

      this.validateResponse(response);

      return response;
   }

   async deleteOrder(token: string = "", orderId: string) {
      const urlParams = {
         token,
         orderId,
      }

      const response = await this.deleteOrderEndpoint.delete(urlParams);

      if (response) {
         this.validateResponse(response);
      }
   }
}

const instance = new OrderDAO();

Object.freeze(instance);

export default instance;