import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import { Grid, Divider, ListItem, List, Grow } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {AppContext} from '../../contexts/AppContext';
import { DBCategory } from '../../model/interface/DBModels';
import CategoryDAO from "../../model/dao/CategoryDAO";

type Props = {
   childClassName: string,
}

export default function CategoryButton(props:Props) {
   const context = useContext(AppContext);

   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const [categories, setCategories] = useState<DBCategory[]>([]);

   const open = Boolean(anchorEl);
   let history = useHistory();

   React.useEffect(() => {
      let isActive = true;

      CategoryDAO.getTopLevelCategories(context.token)
         .then((categories) => {
            if (isActive) {
               setCategories(categories.categories);
            }
         }).catch(() => {
         });

      return () => {
         isActive = false;
      };
   }, []);

   const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget.children[0] as HTMLElement);
   };

   const handlePopperClose = () => {
      setAnchorEl(null);
   };
   
   const handleClick = () => {
      history.push("/categories");
      handlePopperClose();
   }

   const handleItemClick = (name: String) => {
      return () => {
         handlePopperClose();
         history.push("/categories/" + name)
      };
   }

   function handleListKeyDown(event: React.KeyboardEvent) {
      if (event.key === 'Tab') {
         event.preventDefault();
         handlePopperClose();
      }
   }
   
   const categoryListItems = categories.map(
      (category, index) => (
         <div key={index}>
            <ListItem button onClick={handleItemClick(category.name)}>{category.name}</ListItem>
         </div>
      )
   );

   const firstItems = categoryListItems.slice(0, Math.ceil(categoryListItems.length / 3) - 1);
   const middleItems = categoryListItems.slice(
      Math.ceil(categoryListItems.length / 3) - 1,
      (Math.ceil(categoryListItems.length / 3) * 2) - 1
   );
   const lastItems = categoryListItems.slice(
      (Math.ceil(categoryListItems.length / 3) * 2) - 1
   );
   
   return (
      <div className={props.childClassName} onMouseEnter={handlePopperOpen} onMouseLeave={handlePopperClose}>
         <Button
            size="small"
            color="inherit"
            onClick={handleClick}
            endIcon={<ExpandMoreIcon/>}
         >
            All Products
         </Button>
         <Popper
            id="mouse-over-popper"
            className="menu-popper"
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            transition
            
         >
            {({ TransitionProps }) => (
               <Grow {...TransitionProps} timeout={350}>
                  <Grid container alignItems="flex-start" className="grid-list">
                     <Grid item xs={4}>
                        <List dense>
                           {firstItems}
                        </List>
                     </Grid>
                     <Divider orientation="vertical" flexItem />
                     <Grid item xs={4}>
                        <List dense>
                           {middleItems}
                        </List>
                     </Grid>
                     <Divider orientation="vertical" flexItem />
                     <Grid item xs={3}>
                        <List dense>
                           {lastItems}
                        </List>
                     </Grid>
                  </Grid>
               </Grow>
            )}
         </Popper>
      </div>
   );
}