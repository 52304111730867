import React, {useContext, useState} from "react";

import Button, {ButtonProps} from "@material-ui/core/Button";
import {
   DialogContent, DialogActions, Dialog,
   Typography, DialogTitle, Grid,
} from "@material-ui/core";

import {AppContext} from "../../../contexts/AppContext";
import UserDAO from "../../../model/dao/UserDAO";

import SignUp from '../../../login/SignUp';
import LoginHelper from '../../../login/LoginHelper';
import { Credentials } from "../../../model/interface/DBModels";

type Props = ButtonProps & {
  buttonLabel: string,        // label on button
  actionHandler: (creds?: Credentials) => void,  // can be addItems, removeItems, etc
  altCb?: (creds: Credentials) => void,
  disableGuest?: boolean,
}

export default function AccountPromptButton(props:Props) {
   const context = useContext(AppContext);
   const {buttonLabel, actionHandler, altCb, disableGuest, ...rest} = props;

   const [open, setOpen] = useState(false);
   const [showLogin, setShowLogin] = useState(false);
   const [onSignUp, setOnSignUp] = useState(false);

  // ------------- show/hide dialogue ------------- //
  const toggleLoginShow = (signUpClicked: boolean) => {
    setShowLogin(!showLogin);
    setOnSignUp(signUpClicked);
  }

  const showSignUp = () => {
    setOnSignUp(!onSignUp);
  }

  const handleClickOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if(context.token === undefined || context.token === "") {
      setOpen(true);
      setShowLogin(false);
    }
    else { //logged in, do action
      actionHandler();
      handleClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // ------------- set credentials callbacks ------------- //
  const alternateCreds = (creds: Credentials) => {
    console.log("alternateCreds");
    handleClose();
    if (props.altCb) {
      props.altCb(creds);
    }
  }

  const regularAccountHandler = (creds: Credentials) => {
    console.log("closing");
    handleClose();
    actionHandler(creds);
    context.setCredentials(creds);
    console.log("--> action complete");
  }

  const guestAccountHandler = async () => {
    handleClose();
    console.log("--> guestAccountHandler");
    const response = await UserDAO.guestLogin();
    actionHandler(response);
    if(props.altCb) {
      props.altCb(response);
    }
    else {
      context.setCredentials(response);
    }
    
  }

   return (
      <div>
         <Button
            onClick={handleClickOpen}
            fullWidth
            {...rest}
         >
            {buttonLabel}
         </Button>

         <Dialog
            key={"account-dialog"}
            open={open}
            maxWidth="sm"
            fullWidth={true}
            onBackdropClick={handleClose}
            onEscapeKeyDown={handleClose}
            keepMounted
         >
            <DialogTitle>
               <Typography color="primary">
                  You must have an account to do that.
               </Typography>
            </DialogTitle>
            <DialogContent dividers>

              {showLogin ?
                onSignUp ?
                  <div>
                    <SignUp showSignUp={showSignUp} altCb={props.altCb ? alternateCreds : regularAccountHandler} closeCb={handleClose}/>
                  </div>
                :
                  <div>
                    <LoginHelper showSignUp={showSignUp} altCb={props.altCb ? alternateCreds : regularAccountHandler} closeCb={handleClose}/>
                  </div>
              :
                <Grid container direction="column" spacing={2} alignItems="center">
                  <Grid item>
                    <Button variant="contained" color="primary"
                      onClick={() => toggleLoginShow(false)}
                    >
                      Log In
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary"
                      onClick={() => toggleLoginShow(true)}
                    >
                      Sign Up
                    </Button>
                  </Grid>
                  {disableGuest ? 
                    <div></div>
                  : 
                    <Grid item>
                      <Button variant="contained" color="primary" 
                        onClick={() => guestAccountHandler()}
                      >
                        Continue As Guest
                      </Button>
                    </Grid> 
                  }
                </Grid>
              }
            </DialogContent>

            <DialogActions>
               <Button
                  variant="outlined"
                  onClick={handleClose}
               >
                  Cancel
               </Button>
            </DialogActions>
         </Dialog>
      </div>
   );
}
