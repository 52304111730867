import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Typography, Divider } from '@material-ui/core';

import ProjectsList from './ProjectsList';

export default function ProjectsPage() {
   return (
      <div className="page-offset">
         <Grid container>
            <Grid item xs={12}>
               <div className="u-paddingBottom-2">
                  <Typography variant="h4" color="primary" noWrap>
                     My Projects
                  </Typography>
               </div>
            </Grid>
            <Grid item xs={12}>
               <ProjectsList/>
            </Grid>
         </Grid>
      </div>
   );
}