import React, {useContext, useState} from "react";

import Button, {ButtonProps} from "@material-ui/core/Button";
import {
   DialogContent, DialogActions, Dialog,
   Typography, DialogTitle, CircularProgress,
} from "@material-ui/core";

import {AppContext} from "../../../contexts/AppContext";
import OrderDAO from "../../../model/dao/OrderDAO";

type Props = ButtonProps & {
  orderId: string,
  setOrderChanged: any,
}

export default function OrderCancellationButton(props:Props) {
   const context = useContext(AppContext);

   const [open, setOpen] = React.useState(false);
   const [loading, setLoading] = useState<boolean>(false);

  // ------------- show/hide dialogue ------------- //

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // ------------- cancel order ------------- //
  const cancelOrder = async () => {
    setLoading(true);
    await OrderDAO.deleteOrder(context.token, props.orderId);
    setLoading(false);
    props.setOrderChanged(true);
    // setSnackbarOpen(true);
    handleClose();
  }

   return (
      <div>
         <Button variant="outlined" size="small" onClick={handleClickOpen}>
            cancel order
          </Button>

         <Dialog open={open} maxWidth="lg" disableBackdropClick disableEscapeKeyDown keepMounted>
            <DialogTitle>
               <Typography variant="h5" color="primary">
                  Cancel Order
               </Typography>
            </DialogTitle>
            {
              (loading)
              ?
                <DialogContent dividers>
                  <Typography variant="body1" noWrap align="center">
                    <CircularProgress size={'1em'}/>
                  </Typography>
                </DialogContent>
              :
                <DialogContent dividers>
                  <Typography variant="body1">
                      Are you sure you would like to cancel Order #{props.orderId}?
                  </Typography>
                </DialogContent>
            }
            <DialogActions>
              <Button variant="outlined" onClick={handleClose} disabled={loading}>
                  keep order
              </Button>
              <Button variant="contained" color="primary" onClick={cancelOrder} disabled={loading}>
                  cancel order
              </Button>
            </DialogActions>
         </Dialog>
      </div>
   );
}
