import React, { useState, useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Divider, Paper, CircularProgress
} from '@material-ui/core';

import {AppContext} from '../contexts/AppContext';
import ResultDAO from "../model/dao/ResultDAO";

type Props = {
  cartChanged?: boolean,
  priceChanged?: boolean,
  estimatedShipping?: number,
  estimatedTax?: number,
  orderConfirmed?: boolean
};

export default function OrderSummary(props: Props) {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState(false);
  // const [cartChanged, setCartChanged] = useState(false);

  const [cartItemCount, setCartItemCount] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);

  React.useEffect(() => {
     let isActive = true;

     setLoading(true);
     setError(false);

     ResultDAO.getCart({}, context.token)
       .then((cartItems) => {
           if (isActive) {
              setCartItemCount(cartItems.results.length);
              setCartTotal(Number(cartItems.totalPrice));
              setLoading(false);
              setError(false);
           }
       }).catch(() => {
           if (isActive) {
              setError(true);
              setLoading(false);
           }
       });

     return () => {
       isActive = false;
     };
   }, [props.cartChanged, props.priceChanged, context.token]);
   
  const cartTotalFixed = "$" + String(cartTotal.toFixed(2))

  const displayItemCount =
    (cartItemCount === 0) ? "No items"
    : (cartItemCount === 1) ? "1 item"
    : (cartItemCount).toString() + " items"
  ;

  return (
    <div className="order-summary">
      <Paper elevation={3}>
        <Grid container className="cart-right-inner">
          <Grid item xs={12}>
            <Typography variant="h6" display="inline">
              Order Summary
            </Typography>
            <Typography variant="subtitle1" display="inline" className="cart-item-count">
              • {displayItemCount}
            </Typography>
          </Grid>

          <Grid item container xs={12} className="cart-padding-top" 
            alignItems="flex-end" justify="space-between"
          >
            <Typography variant="body1" align="left">
              <b>Subtotal</b>
            </Typography>
            <Typography variant="body2" align="right">
              {
                (loading) 
                ? <CircularProgress size={20}/>
                : cartTotalFixed
              }
            </Typography>
          </Grid>

          <Grid item container xs={12} 
            alignItems="baseline" justify="space-between"
          >
            <Grid item xs>
              <Typography variant="body1" align="left">
                Shipping
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body2" align="right">
                {
                  (props.estimatedShipping !== undefined)
                  ? props.estimatedShipping
                  : "Calculated at checkout"
                }
              </Typography>
            </Grid>
          </Grid>
          
          <Grid item container xs={12}
            alignItems="baseline" justify="space-between"
          >
            <Grid item xs>
              <Typography variant="body1" align="left">
                Est. Taxes & Fees
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body2" align="right">
                {
                  (props.estimatedTax !== undefined)
                  ? props.estimatedTax
                  : "Calculated at checkout"
                }
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} className="cart-padding-top cart-padding-bottom">
            <Divider />
          </Grid>

          <Grid item container xs={12} 
            alignItems="baseline" justify="space-between"
          >
            <Grid item xs>
              <Typography variant="subtitle1" align="left">
               <b>Est. Total</b>
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1" align="right" className="cart-price-text" key={cartTotal}>
                {cartTotalFixed}
              </Typography>
            </Grid>
          </Grid>

        </Grid>
      </Paper>
      <br />
      {
        (props.orderConfirmed === true)
        ?
          ""
        :
          <Paper elevation={3}>
            <Typography variant="body2" className="cart-right-inner">
              <b>Availability, shipping, tax & promotions</b> are not final until you complete your order.
            </Typography>
          </Paper>
      }

    </div>
  );
}
