import React, { useContext, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import { Grid, Divider, CircularProgress } from '@material-ui/core';

import {AppContext} from '../../contexts/AppContext';
import CategoryGrid from "./CategoryGrid";
import CategoryDAO from "../../model/dao/CategoryDAO";
import {
   DBCategory,
} from '../../model/interface/DBModels';

export default function CategoriesPage() {

   const context = useContext(AppContext);
   const [categories, setCategories] = useState<DBCategory[]>([]);
   const [isLoading, setIsLoading] = useState(false);
   const [errorMessage, setError] = useState(false);

   React.useEffect(() => {
      let isActive = true;

      setIsLoading(true);
      setError(false);

      CategoryDAO.getTopLevelCategories(context.token)
         .then((categories) => {
            if (isActive) {
               setCategories(categories.categories);
               setIsLoading(false);
               setError(false);
            }
         }).catch(() => {
            if (isActive) {
               setError(true);
               setIsLoading(false);
            }
         });

      return () => {
         isActive = false;
      };
   }, []);

   return (
      <div className="categories-page page-offset">
         <Grid container>
            <Grid item xs={12}>
               <Typography variant="h5" noWrap>
                  All Products
               </Typography>
               <Divider/>
            </Grid>
            <Grid item xs={12}>
               { isLoading ? 
                  <CircularProgress size={"4em"}/>
               :
                  <CategoryGrid {...{categoryList: categories, fromTop: true}}/>
               }
            </Grid>
         </Grid>
      </div>
   );
}