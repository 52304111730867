import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  NavLink as RouterLink,
} from 'react-router-dom';


import {
   Button, TableRow, TableCell, Hidden, Grid, Typography, Link,
} from '@material-ui/core';
import { DBOrder } from '../../../model/interface/DBModels';

// •

type Props = {
  order: DBOrder,
};

const OrderRow = function(props: Props) {
  let history = useHistory();
  const orderDate = new Date(props.order.created);
  const orderDateParsed = 
    new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(orderDate)

  const goToOrder = (id: string) => {
    history.push({
        pathname: "/orders/" + id
    });
  }

  return (
     <TableRow>  {/* one row = one item */}
      <Hidden mdDown> {/* large version */}
        <TableCell component="th" scope="row"> {/* order info */}
          {props.order.id}
        </TableCell>
        <TableCell align="left"> {/* date */}
          {orderDateParsed}
        </TableCell>
        <TableCell align="left"> {/* status */}
          {props.order.status}
        </TableCell>
        <TableCell align="center"> {/* view */}
          <Button
          variant="contained" color="inherit" component={RouterLink} to={"/orders/" + props.order.id}
          className="checkout-button" disableElevation
          >
            view order
          </Button>
        </TableCell>
      </Hidden>
      <Hidden lgUp> {/* small version */}
        <TableCell onClick={() => goToOrder(props.order.id)}>
          <div className="orderRowInner">
            <Link display="inline" onClick={() => goToOrder(props.order.id)}>
              <strong>{props.order.id}</strong>
            </Link><br/>
            Ordered on: {orderDateParsed}<br/>
            Status: {props.order.status}
          </div>
        </TableCell>
        {/* <TableCell align="center">
          <Button
            variant="contained" color="inherit" component={RouterLink} to={"/orders/" + props.order.id}
            className="checkout-button" disableElevation
          >
            view order
          </Button>
        </TableCell> */}
      </Hidden>
     </TableRow>
   );
}

export default OrderRow;